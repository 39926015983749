/**
 * Sidebar
 */
.sidebar {
  &__card,
  .card:not(.modal) {
    display: block;
    padding: 1rem;
    @include rem(margin-bottom, 30px);

    h4 {
      margin-top: 1rem;
    }

    .card-title {
      margin-top: 0;
      @include rem(font-size, 18px);
    }
  }

  &__card-meta {
    .likes {
      color: $gray-600;
      i {
        color: nth($gray-dark-color, 5);
      }
    }
  }

  &__list {
    a {
      display: block;
      font-weight: 600;
      line-height: (28/13);
    }

    .title {
      @include rem(font-size, 16px);
      line-height: (24/16);

      a {
        line-height: inherit;
      }
    }
  }

  &__contacts {
    font-weight: 700;

    > li {
      margin-bottom: 1.25rem;
    }

    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__finder {
    label {
      display: block;
      margin-bottom: 0.50rem;
      line-height: 1;
    }

    .form-control {
      &.date {
        @include rem(padding-left, 38px);
      }
    }

    .form-group {
      margin-bottom: 1rem;

      i.icon {
        color: $gray-600;
      }
    }

    .row {
      margin: 0 -5px;

      > * {
        padding: 0 5px;
      }
    }

    @include media('<lg') {
      margin-bottom: 0!important;
    }
  }
}

/**
 * Sitebar guests
 */
.guests-room {
  &__item {
    @include rem(margin-bottom, 30px);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__desc {
    position: relative;
    @include rem(padding, 20px 12px);
    @include rem(margin-bottom, 28px);

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 24px;
      border-style: solid;
      @include rem(border-width, 17px 8px 0px);

      html.rtl & {
        left: auto;
        right: 24px;
      }
    }

    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__bottom {
    @include rem(margin-left, 12px);

    html.rtl & {
      margin-left: 0;
      @include rem(margin-right, 12px);
    }
  }

  &__avatar {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 40px;
    height: 40px;
    background-color: $primary-light;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1rem;
    border: 1px solid $hr-border-color;
    background-color: nth($gray-light-color, 8);
    overflow: hidden;

    img {
      width: 54px;
    }

    html.rtl & {
      margin-right: 0;
      margin-left: 1rem;
    }
  }

  .country {
    color: $gray-600;
  }
}
