// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$call-name: 'call';
$call-x: 0px;
$call-y: 0px;
$call-offset-x: 0px;
$call-offset-y: 0px;
$call-width: 32px;
$call-height: 32px;
$call-total-width: 40px;
$call-total-height: 112px;
$call-image: 'sprite.png';
$call: (0px, 0px, 0px, 0px, 32px, 32px, 40px, 112px, 'sprite.png', 'call', );
$facebook-name: 'facebook';
$facebook-x: 0px;
$facebook-y: 32px;
$facebook-offset-x: 0px;
$facebook-offset-y: -32px;
$facebook-width: 40px;
$facebook-height: 40px;
$facebook-total-width: 40px;
$facebook-total-height: 112px;
$facebook-image: 'sprite.png';
$facebook: (0px, 32px, 0px, -32px, 40px, 40px, 40px, 112px, 'sprite.png', 'facebook', );
$google-plus-name: 'google-plus';
$google-plus-x: 0px;
$google-plus-y: 72px;
$google-plus-offset-x: 0px;
$google-plus-offset-y: -72px;
$google-plus-width: 40px;
$google-plus-height: 40px;
$google-plus-total-width: 40px;
$google-plus-total-height: 112px;
$google-plus-image: 'sprite.png';
$google-plus: (0px, 72px, 0px, -72px, 40px, 40px, 40px, 112px, 'sprite.png', 'google-plus', );
$spritesheet-width: 40px;
$spritesheet-height: 112px;
$spritesheet-image: 'sprite.png';
$spritesheet-sprites: ($call, $facebook, $google-plus, );
$spritesheet: (40px, 112px, 'sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
