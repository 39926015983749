// Scale up the modal
@include media-breakpoint-up(sm) {
  .modal-dialog {
    max-width: $modal-md;
  }
}

.modal--full {
  padding-right: 0 !important;

  .modal-dialog {
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .modal-content {
    height: 100%;
    border: none;
    box-shadow: none;
  }
}

.modal-content {
  border-radius: 0;
  border-color: nth($gray-dark-color, 5);
  background-color: #fff;
}

.close {
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  color: inherit;
  text-shadow: none;
}

body.modal-open,
.modal-backdrop {
  -webkit-overflow-scrolling: touch;
}
