.demo-panel {
  direction: ltr;
  text-align: left;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
  z-index: 2;
  transition: transform 300ms ease 1.5s;
  background-color: $gray-100;

  &:hover {
    transform: translateY(0);
    transition: transform 300ms ease 150ms;

    .demo-panel__toggle {
      i.fa-cog {
        animation: rotateIn 1s backwards;
      }
    }
  }

  h4 {
    margin: 0;
    margin-right: 0.75rem;
  }

  &__toggle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 30px;
    font-size: 1.50rem;
    margin-top: -30px;
    border-left: 1px solid $gray-200;
    border-radius: 6px 6px 0 0;

    i.fa-cog {
      animation: rotateOut 1s backwards;
    }
  }

  @keyframes rotateIn {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes rotateOut {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }

  &__content {
    overflow: hidden;
    overflow-x: auto;

    .container {
      min-width: 360px;
    }
  }

  &__colors {
    .nav-link {
      display: inline-block;
      border-radius: 50%;
      padding: 1px;
      margin-bottom: 0.25rem;
      border: 2px solid #fff;

      &:after {
        display: block;
        content: '';
        background-color: currentColor;
        @include rem(width, 26px);
        @include rem(height, 26px);
        border-radius: 50%;
        @include shadow-2dp;
      }

      &.is-active {
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
      }

      &[data-path*=default] {
        color: $teal;
      }
      &[data-path*=orange] {
        color: $orange;
      }
      &[data-path*=green] {
        color: $green-light;
      }
      &[data-path*=red] {
        color: $red-light;
      }
      &[data-path*=purple] {
        color: $purple;
      }
      &[data-path*=pink] {
        color: $pink;
      }
      &[data-path*=indigo] {
        color: $indigo;
      }
    }
  }
}
