/**
 * Page contacts
 */
.contacts-layer {
  position: relative;
  width: 100%;
  height: 100vw;
  padding: 1rem;
  @include rem(margin-bottom, 30px);

  &__box {
    position: relative;
    height: 100%;
  }

  &__map {
    height: 100%;
  }

  @include media('>sm') {
    height: 50vh;
  }

  @include media('landscape') {
    height: 85vh;
  }

  @include media('>lg') {
    height: auto;
    padding: 1.75rem;
  }
}
