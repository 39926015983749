/*
 * Category Sortbar
 */
.sortbar {
  padding: 0;
  @include rem(margin-bottom, 30px);
  border: 1px solid $gray-400;
  font-size: 14px;
  color: $gray-600;
  background-color: $white;

  .navbar-brand,
  .nav-link {
    background-color: #fff;

    &:hover {
      background-color: $gray-100;
    }

    &:active,
    &:focus {
      background-color: $gray-200;
    }

    &:after {
      margin: 0;
    }
  }

  .navbar-brand {
    @include rem(padding, 0 30px 0 1rem);
    margin: 0;
    color: nth($gray-dark-color, 5);

    html.rtl & {
      @include rem(padding, 0 1rem 0 30px);
    }
  }

  .nav-link {
    display: flex;
    align-items: center;
    font-size: inherit;
    color: inherit;
    white-space: nowrap;
  }

  &__sort {
    flex-grow: 1;
    @include rem(padding, 9px 0px);

    .nav-link {
      border-right: 1px solid nth($gray-dark-color, 5);
      @include rem(padding, 6px 14px);

      &:after {
        display: inline-block;
        margin-left: 1rem;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: '\f175';

        html.rtl & {
          margin-left: 0;
          margin-right: 1rem;
        }
      }

      &.asc:after {
        content: '\f176';
      }

      &.desc:after {
        content: '\f175';
      }
    }
  }

  &__grid {
    html.rtl & {
      padding-right: 0;
    }

    .nav-link {
      padding: 0 1.25rem;
      font-size: 1.25rem;
      line-height: 1;
    }
  }

  @include media('>lg') {
    .navbar-brand {
      pointer-events: none;
    }
  }

  @include media('<lg') {
    .navbar-brand {
      padding: 0 1.75rem;

      html.rtl & {
        padding: 0 1.75rem;
      }
    }
  }

  @include media('<md') {
    > * {
      flex-grow: 1;
      width: 25%;
      padding: 0;
      margin: 0;

      > * {
        flex-grow: 1;
      }

      html.rtl & {
        padding: 0;
      }
    }

    .navbar-brand,
    .nav-link {
      justify-content: center;
      padding: 1rem 0.8rem;

      span {
        display: none;
      }
    }

    &__sort {
      order: 1;

      .nav-link {
        display: none;
        border-right: 0;

        &:after {display: none}

        &.asc,
        &.desc {
          &:after { display: block; }

          i {
            display: none;
          }
        }
      }
    }

    &__grid {
      width: 50%;
    }
  }
}
