/**
* Sidebar Filters
*/
.sidebar-filter {
  .btn-toggle {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: auto;
    width: $btn-toggle-menu-width;
  }

  .modal-header {
    flex-wrap: wrap;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 1rem;
    border: none;
    border-radius: 0;
    z-index: 10;
  }

  .modal-title {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.75rem 1rem;
    margin: 0 -1rem;
    color: nth($gray-dark-color, 5);
    white-space: nowrap;
    border-bottom: 1px solid $hr-border-color;
    color: inherit;

    .search-result {
      white-space: nowrap;
    }

    .clear {
      flex-shrink: 0;
      font-size: 1.4rem;
      opacity: 1;
      color: inherit;

      &:hover {
        opacity: 0.60;
      }
    }
  }

  .modal-dialog {
    max-width: map-get($container-max-widths, "md");
    padding-left: 24px;
    margin: 5rem auto 0;

    html.rtl & {
      padding-left: 0;
      padding-right: 24px;
    }
  }

  .modal-content {
    border: none;
  }

  .modal-body {
    padding-top: 0;
  }

  @include media('>lg') {
    &.modal {
      display: block!important;
      position: static;
      opacity: 1;
      left: auto;
      right: auto;
    }

    .btn-toggle {
      display: none;
    }

    .modal-header {
      position: static;
    }

    .modal-title {
      padding: 0.65625rem 0;
      margin: 0;
    }

    .search-result {
      flex-grow: 1;
    }

    .modal-dialog {
      padding: 0;
      margin: 0;
      transform: none!important;
    }
  }

  &__item {
    border-bottom: 1px solid $hr-border-color;

    &:last-child {
      border-bottom: none;
    }

    .form-group {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    margin: 0;
    z-index: 3;
    font-weight: 600;
    font-family: inherit;
  }

  &__toggle {
    @include rem(margin, 12px 0);
    font-size: 14px;
    color: inherit;
    background-color: $white;

    i {
      font-size: 18/14 + em;
      line-height: (24/18);
      transform: rotate(0);
      transition: transform 300ms;
      color: nth($gray-dark-color, 5);
    }

    &[aria-expanded=false] {
      i {
        transform: rotate(-90deg);
      }
    }
  }

  &__content {
    padding-bottom: 14px;

    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__stars {
    font-size: 1.25rem;

    .amount {
      color: $gray-600;
      font-size: 12px;
    }
  }

  &__rating {
    margin-bottom: 12px;

    .amount {
      font-size: 14px;
      width: 1.25rem;
      line-height: 1;
      text-align: left;
      border: none;

      &:last-child {
        text-align: right;
      }
    }
  }
}
