/**
 * Breadcrumbs
 */

.breadcrumb-nav {
  position: relative;
  background-color: $body-bg;
  z-index: 1;

  .navbar-brand {
    padding: 1rem;
    margin: 0;
    @include shadow-12dp();
  }
}

.breadcrumb {
  @include rem(padding, 20px 0);
  margin-bottom: 0;
  background-color: transparent;
  border-radius: 0;
  color: $gray-600;
}

.breadcrumb-item {
  // The separator between breadcrumbs (by default, a forward-slash: "/")
  + .breadcrumb-item::before {
    display: inline-block; // Suppress underlining of the separator in modern browsers
    @include rem(padding, 0 12px);
    color: inherit;
    content: "|";
  }

  // IE9-11 hack to properly handle hyperlink underlines for breadcrumbs built
  // without `<ul>`s. The `::before` pseudo-element generates an element
  // *within* the .breadcrumb-item and thereby inherits the `text-decoration`.
  //
  // To trick IE into suppressing the underline, we give the pseudo-element an
  // underline and then immediately remove it.
  + .breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  // stylelint-disable-next-line no-duplicate-selectors
  + .breadcrumb-item:hover::before {
    text-decoration: none;
  }

  &.active {
    color: $breadcrumb-active-color;
  }
}

