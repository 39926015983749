/**
 * Flights
 */
.list-flight-item {
  @include rem(margin-bottom, 30px);
}

.flight-card {

  &__row {
    .list-group-item {
      &:last-child {
        border-bottom: none;
      }
    }

    .title {
      margin-left: auto;
      font-weight: 700;
      font-size: 16px;
      line-height: 1.2;
      text-align: center;
      white-space: nowrap;
    }
  }

  &__total {
    .total {
      font-weight: 700;
      @include rem(font-size, 30px);
    }

    .btn {
      padding: 0.75rem 1.85rem;
    }
  }

  @include media('>md') {
    @include rem(padding, 10px 20px);

    .list-group-item {
      padding: 10px;
      margin: 0;
      border: none;

      > .row {
        margin: 0;

        > * {
          padding: 0;

          .ie11 & {
            flex-basis: auto;
          }
        }
      }
    }

    &__row {
      border-right: 1px solid $hr-border-color;

      html.rtl & {
        border-right: none;
        border-left: 1px solid $hr-border-color;
      }

      .list-group-item {
        width: 20%;
      }
    }
  }
}
