/**
 * Table of contents
 * Base tags and global classess
 * Typography
 * Components
 * Blocks
    * Breadcrumbs
    * Pagination
    * Site header
    * Navigation menu
    * Flight searchbar
    * Intro home
    * Intro pages
    * Intro searchbar
    * Cards
    * Category page, items, list & grid, banners
    * Category Sortbar
    * Hotel, navigation, cards, guest rooms
    * Hotel check items
    * Hotel Gallery
    * Flights
    * Blog list, blog posts
    * Page contacts
    * Sidebar
    * Sitebar Guests
    * Sidebar Category
    * Site footer
  * Backgrounds and colors theme
  * Edit RTL
  * Demo Switcher
*/
@font-face {
  font-family: "Icon";
  src: url("fonts/Icon.eot");
  src: url("fonts/Icon.eot?#iefix") format("eot"), url("fonts/Icon.woff2") format("woff2"), url("fonts/Icon.woff") format("woff"), url("fonts/Icon.ttf") format("truetype"), url("fonts/Icon.svg#Icon") format("svg");
}

.icon:before {
  font-family: "Icon";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
  text-decoration: none;
  text-transform: none;
}

.icon-24-hours:before {
  content: "\E001";
}

.icon-airplane:before {
  content: "\E002";
}

.icon-badge:before {
  content: "\E003";
}

.icon-bellboy:before {
  content: "\E004";
}

.icon-bubbles:before {
  content: "\E005";
}

.icon-building:before {
  content: "\E006";
}

.icon-calendar:before {
  content: "\E007";
}

.icon-call:before {
  content: "\E008";
}

.icon-check-button:before {
  content: "\E009";
}

.icon-chevron-left:before {
  content: "\E00A";
}

.icon-chevron-right:before {
  content: "\E00B";
}

.icon-cleaning:before {
  content: "\E00C";
}

.icon-close:before {
  content: "\E00D";
}

.icon-coffee-cup:before {
  content: "\E00E";
}

.icon-comments:before {
  content: "\E00F";
}

.icon-credits:before {
  content: "\E010";
}

.icon-dinner:before {
  content: "\E011";
}

.icon-disabled:before {
  content: "\E012";
}

.icon-envelope-close:before {
  content: "\E013";
}

.icon-family:before {
  content: "\E014";
}

.icon-filter:before {
  content: "\E015";
}

.icon-food:before {
  content: "\E016";
}

.icon-freezer:before {
  content: "\E017";
}

.icon-grid-mb:before {
  content: "\E018";
}

.icon-grid:before {
  content: "\E019";
}

.icon-info:before {
  content: "\E01A";
}

.icon-label:before {
  content: "\E01B";
}

.icon-like-down:before {
  content: "\E01C";
}

.icon-like-up:before {
  content: "\E01D";
}

.icon-list-mb:before {
  content: "\E01E";
}

.icon-list:before {
  content: "\E01F";
}

.icon-login:before {
  content: "\E020";
}

.icon-logout:before {
  content: "\E021";
}

.icon-long-arrow-left:before {
  content: "\E022";
}

.icon-long-arrow-right:before {
  content: "\E023";
}

.icon-minus-inside:before {
  content: "\E024";
}

.icon-minus:before {
  content: "\E025";
}

.icon-no-smoking:before {
  content: "\E026";
}

.icon-parking:before {
  content: "\E027";
}

.icon-pets:before {
  content: "\E028";
}

.icon-phone:before {
  content: "\E029";
}

.icon-plus-inside:before {
  content: "\E02A";
}

.icon-plus:before {
  content: "\E02B";
}

.icon-receptionist:before {
  content: "\E02C";
}

.icon-search:before {
  content: "\E02D";
}

.icon-sort-duble:before {
  content: "\E02E";
}

.icon-tags:before {
  content: "\E02F";
}

.icon-thumbs-up:before {
  content: "\E030";
}

.icon-user:before {
  content: "\E031";
}

.icon-wifi:before {
  content: "\E032";
}

.icon,
.s-icon {
  vertical-align: middle;
  display: inline-block;
  line-height: 1;
}

.icon:before,
.s-icon:before {
  display: block;
}

.icon svg {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  font-size: inherit;
}

.s-icon:before {
  content: '';
}

.s-icon--call:before {
  background-image: url(img/sprite.png);
  background-position: 0px 0px;
  width: 32px;
  height: 32px;
}

.s-icon--facebook:before {
  background-image: url(img/sprite.png);
  background-position: 0px -32px;
  width: 40px;
  height: 40px;
}

.s-icon--google-plus:before {
  background-image: url(img/sprite.png);
  background-position: 0px -72px;
  width: 40px;
  height: 40px;
}

/**
* Base tags and global classess
*/
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 13px;
  color: #444444;
  background-color: #e8e5df;
}

html.rtl body {
  direction: rtl;
  text-align: inherit;
}

@media (max-width: 1199px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 575px) {
  html {
    font-size: 13px;
  }
}

main {
  flex-grow: 1;
}

a {
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  text-decoration: underline;
}

a.btn-link {
  text-decoration: none;
}

a.btn-link:hover {
  text-decoration: none;
}

button {
  padding: 0;
  border: none;
  background-color: transparent;
  outline: none;
}

.pointer {
  cursor: pointer;
  outline: none;
}

.point-fade:not(button):hover {
  opacity: 0.60;
}

button.point-fade:hover, .btn.point-fade:hover {
  transition: opacity 115ms;
  opacity: 0.85;
}

button.point-fade:active, .btn.point-fade:active {
  opacity: 0.70;
}

.point-under:hover {
  text-decoration: underline;
}

/**
* Typography
*/
h1, .h1 {
  font-size: 2.25rem;
}

h2, .h2 {
  font-size: 1.75rem;
}

h3, .h3 {
  font-size: 1.5rem;
}

h4, .h4 {
  font-size: 1.25rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.85rem;
}

h1, .h1,
h2, .h2 {
  margin-bottom: 1.50rem;
  font-family: "Poppins", sans-serif;
}

h3, .h3,
h4, .h4 {
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif;
}

figure {
  margin-bottom: 1.50rem;
}

aside h4, aside h5 {
  font-family: "Poppins", sans-serif;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

i.bullet, ul.list-inline > li:after {
  display: inline-block;
  vertical-align: middle;
  margin: 0 4px;
  width: 4px;
  height: 4px;
  background-color: currentColor;
  border-radius: 50%;
}

ul.list-styled {
  list-style-type: disc;
  margin-left: 1.5rem;
}

html.rtl ul.list-styled {
  margin-left: 0;
  margin-right: 1.5rem;
}

ul.list-bullets li {
  position: relative;
  padding-left: 1.25rem;
  line-height: 1.84615;
}

ul.list-bullets li:before {
  content: '';
  position: absolute;
  left: 0;
  top: .5rem;
  width: calc(.25rem + 2px);
  height: calc(.25rem + 2px);
  background-color: currentColor;
  border-radius: 50%;
}

html.rtl ul.list-bullets li {
  padding-left: auto;
  padding-right: 1.25rem;
}

html.rtl ul.list-bullets li:before {
  left: auto;
  right: 0;
}

ul.list-inline > li {
  display: inline-block;
}

ul.list-inline > li > * {
  vertical-align: top;
}

ul.list-inline > li:after {
  content: '';
  margin-left: 6px;
}

ul.list-inline > li:last-child:after {
  display: none;
}

p {
  margin-bottom: 1.25rem;
}

blockquote .media-object {
  margin: -0.12222em 0.2em 0 0;
  font-size: 5.625rem;
  line-height: 1;
}

html.rtl blockquote .media-object {
  margin-right: 0;
  margin-left: 0.2em;
}

.divider {
  display: flex;
  margin-bottom: 1.25rem;
}

.divider::after, .divider::before {
  content: '';
  position: relative;
  display: block;
  background-color: #dcdcdc;
  border-top: 1px solid #dcdcdc;
  flex-grow: 1;
}

.divider-horizontal {
  flex-direction: row;
  align-items: center;
}

.fw-light {
  font-weight: 300;
}

.fw-norm {
  font-weight: 400;
}

.fw-md {
  font-weight: 500;
}

.fw-sm {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}

.fw-black {
  font-weight: 900;
}

.fz-xs {
  font-size: 11px;
}

.fz-small {
  font-size: 12px;
}

.fz-norm {
  font-size: 1rem;
}

.f-primary {
  font-family: inherit;
}

.mark {
  display: inline-block;
  padding: 0.50rem 1rem;
  border-radius: 1.75rem;
}

.img-cover,
.jarallax-img,
.jarallax > .jarallax-img, .product__img-top img, .hotel-package__img img {
  position: absolute;
  object-fit: cover;
  /* support for plugin https://github.com/bfred-it/object-fit-images */
  font-family: 'object-fit: cover;';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.jarallax {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/**
* Components:  grid, alerts, tooltips, buttons, scrollbars, inputs, checkboxes
* custom select, range slider, datepicker, progress bars, modals
*/
.grid-sizer {
  min-height: 0;
  padding: 0;
  margin: 0;
}

.row:not(.no-gutters) {
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}

.row:not(.no-gutters) > * {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.pad-10:not(.no-gutters) {
  margin-left: 10px;
  margin-right: 10px;
}

.pad-10:not(.no-gutters) > * {
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 1199px) {
  .pad-lg-20:not(.no-gutters) {
    margin: 0 -10px;
  }
  .pad-lg-20:not(.no-gutters) > * {
    padding: 0 10px;
  }
}

@media (max-width: 767px) {
  .container {
    max-width: none;
  }
}

.sticky {
  position: fixed;
}

.alert {
  border-width: 0;
  border-left-width: 4px;
  border-radius: 0;
}

html.rtl .alert {
  border-left-width: 0;
  border-right-width: 4px;
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.95;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.5rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.5rem 0.5rem 0;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.5rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.5rem;
  height: 1rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-width: 0;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.5rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.5rem 0.5rem;
  border-right-width: 0;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.5rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.5rem;
  height: 1rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.tooltip-inner {
  max-width: 180px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  border-radius: 0;
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.br-widget {
  display: flex;
}

.br-widget > * {
  margin-right: 0.25rem;
}

html.rtl .br-widget > * {
  margin-right: 0;
  margin-left: 0.25rem;
}

.rating {
  line-height: 1;
}

.rating--lg {
  font-size: 1.125rem;
}

.rating .br-widget {
  height: auto;
  display: flex;
}

.rating .br-widget a {
  font-size: inherit;
  color: currentColor;
}

.rating .br-widget a:after {
  color: currentColor !important;
}

.btn {
  font-weight: 400;
  padding: 0.75rem 2.25rem;
  font-size: 13px;
  line-height: 1;
  text-transform: uppercase;
  cursor: pointer;
}

.btn--round {
  border-radius: 1.875rem;
}

.hotel-card .btn {
  max-width: 220px;
  width: 100%;
  padding: 0.75rem 1rem;
  margin: 0 auto;
}

.icon-caret {
  font-size: 1rem;
}

.btn-load {
  position: relative;
  width: 155px;
  height: 40px;
  padding: 0;
  transition: all 0.2s ease-out;
}

.btn-load .fa-check {
  font-size: 20px;
}

.btn-load.loading {
  position: relative;
  padding: 0;
  width: 40px;
}

.btn-load.complete .fa-check {
  transform: scale(0);
  animation: check 0.2s ease-in-out 0.1s forwards;
}

.btn-load .spinner {
  display: block;
  margin: 0 auto;
  width: 34px;
  height: 34px;
  border-top: 4px solid currentColor;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-radius: 100%;
  animation: spin 0.6s ease-out infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes check {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.btn-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 24px;
  transition: 120ms;
  z-index: 1;
}

.btn-nav--up {
  display: none;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 10;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

html.rtl .btn-nav--up {
  right: auto;
  left: 1rem;
}

.btn-nav--up i {
  transform: rotate(-180deg);
  transition: transform 120ms linear;
}

.btn-nav--up.bottom {
  position: absolute;
}

.btn-nav--up.bottom i {
  transform: rotate(0);
}

.btn-toggle {
  direction: ltr;
  display: flex;
  padding: 0;
  width: auto;
  justify-content: center;
  border-radius: 0;
}

.btn-toggle .icon-bar {
  height: 26px;
  display: flex;
  align-items: center;
}

.btn-toggle .icon-bar:before, .btn-toggle .icon-bar:after {
  content: '';
  display: block;
  width: 12px;
  height: 2px;
  margin-top: 0.7rem;
  background-color: #444444;
  transition: 200ms ease;
}

.btn-toggle .icon-bar:before {
  margin-right: -3px;
  transform-origin: 100% 0;
  transform: rotate(45deg);
}

.btn-toggle .icon-bar:after {
  transform-origin: 0 0;
  transform: rotate(-45deg);
}

.btn-toggle[aria-expanded=true] .icon-bar:before, .btn-toggle[aria-expanded=true] .icon-bar:after {
  margin-top: -0.4rem;
}

.btn-toggle[aria-expanded=true] .icon-bar:before {
  margin-right: 0;
  transform: rotate(-45deg);
}

.btn-toggle[aria-expanded=true] .icon-bar:after {
  transform: rotate(45deg);
}

.btn-switch {
  direction: ltr;
  position: relative;
  width: 44px;
  z-index: 0;
  cursor: pointer;
}

.btn-switch .bar {
  border-radius: 8px;
  height: 16px;
  left: 3px;
  opacity: 0.4;
  position: absolute;
  top: 2px;
  width: 38px;
  z-index: 0;
  transition: all 300ms linear, background-color linear 80ms;
}

.btn-switch .toggle {
  display: block;
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
  height: 20px;
  position: relative;
  transition: transform linear 80ms, background-color linear 80ms;
  width: 20px;
  z-index: 1;
}

.btn-switch.is-active .bar {
  opacity: 1;
}

.btn-switch.is-active .toggle {
  transform: translate3d(24px, 0, 0);
}

.qty .ui-spinner {
  padding-right: 28px;
  border: none;
  border-radius: 0;
}

html.rtl .qty .ui-spinner {
  padding-right: 0;
  padding-left: 28px;
}

.qty .ui-spinner-input {
  width: 25px;
  height: 25px;
  line-height: 24px;
  padding: 0;
  margin: 0;
  text-align: center;
  border: 1px solid #333333;
}

.qty .ui-spinner-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 0 0.625rem;
  opacity: 0.60;
  cursor: pointer;
}

html.rtl .qty .ui-spinner-button {
  right: auto;
  left: 0;
}

.qty .ui-spinner-button::after {
  content: '';
  border-style: solid;
  border-width: 8px 4px;
  border-color: #333333 transparent;
}

.qty .ui-spinner-up::after {
  border-top-width: 0;
}

.qty .ui-spinner-down::after {
  border-bottom-width: 0;
}

.noUi-horizontal.noUi-ltr {
  height: 6px;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  min-width: 100px;
}

.noUi-horizontal.noUi-ltr .noUi-connect {
  box-shadow: none;
  cursor: pointer;
}

.noUi-horizontal.noUi-ltr .noUi-handle {
  top: -8px !important;
  right: -8px !important;
  width: 18px;
  height: 18px;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.noUi-horizontal.noUi-ltr .noUi-handle:before, .noUi-horizontal.noUi-ltr .noUi-handle:after {
  display: none;
}

.mCSB_inside > .mCSB_container {
  margin-right: -1px;
}

.mCSB_scrollTools {
  width: 1rem;
}

.mCSB_scrollTools .mCSB_draggerContainer {
  margin: 1rem 0 !important;
}

.mCSB_scrollTools .mCSB_draggerRail {
  width: 1rem;
  margin: 0;
  border-radius: 0;
  background-color: #666666;
  opacity: 0;
  transition: 200ms, opacity 200ms 1.5s;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px;
  margin: 0 0 0 auto;
  background-color: #ced4da !important;
  transition: 200ms, width 200ms 1.5s;
  border-radius: 0;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar:hover {
  background-color: #dcdcdc !important;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar:active {
  background-color: #e9ecef !important;
}

.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonUp {
  width: 1rem;
  height: 1rem;
  font-size: 12px;
  line-height: 1.16667;
  font-family: FontAwesome;
  text-rendering: auto;
  text-align: center;
  background-color: #666666;
  opacity: 0;
  transition: 200ms, opacity 200ms 1.5s;
}

.mCSB_scrollTools .mCSB_buttonDown:before,
.mCSB_scrollTools .mCSB_buttonUp:before {
  display: inline-block;
  vertical-align: middle;
}

.mCSB_scrollTools .mCSB_buttonUp:before {
  content: '\f106';
}

.mCSB_scrollTools .mCSB_buttonDown:before {
  content: '\f107';
}

.mCSB_scrollTools:hover .mCSB_buttonUp,
.mCSB_scrollTools:hover .mCSB_buttonDown,
.mCSB_scrollTools:hover .mCSB_draggerRail {
  opacity: 1 !important;
  transition: 200ms;
}

.select-dropdown .mCSB_scrollTools:hover .mCSB_buttonUp, .select-dropdown
.mCSB_scrollTools:hover .mCSB_buttonDown, .select-dropdown
.mCSB_scrollTools:hover .mCSB_draggerRail {
  opacity: 0 !important;
}

.mCSB_scrollTools:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  transition: 120ms;
}

.select-dropdown .mCSB_scrollTools:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 2px;
}

.form-control,
.select2-container .select2-selection--single {
  height: 35px;
  padding: 7px 16px;
  border-radius: 1.25rem;
  font-weight: 600;
  font-size: 13px;
  color: #444444;
}

.form-control:focus,
.select2-container .select2-selection--single:focus {
  color: #444444;
  background-color: #fff;
  border-color: #1dc6c8;
  outline: 0;
  box-shadow: 0 0 0 0.2rem transparent;
}

.has-error .form-control, .has-error
.select2-container .select2-selection--single {
  color: inherit;
  border-color: currentColor;
}

.form-control.hidden {
  position: absolute;
  left: 0;
  right: 0;
  height: 0;
  padding: 0;
  margin: auto;
  opacity: 0;
  z-index: -1;
}

.form-control.date {
  display: inline;
  padding: 0;
  border: none;
  border-radius: 0;
  vertical-align: middle;
  cursor: pointer;
}

.form-control:focus ~ .form-control.date {
  border-bottom: 1px dotted;
}

.form-control-sm {
  height: 2.5rem;
  padding: 0.625rem 1rem;
  border-radius: 1.50rem;
}

.form-control-lg {
  height: 3.125rem;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5;
}

textarea.form-control {
  height: auto;
  border-radius: 6px;
  resize: none;
}

.input-group-prepend,
.input-group-append {
  position: relative;
}

.input-group-prepend .icon,
.input-group-append .icon {
  position: absolute;
  top: 50%;
  margin-top: -1px;
  transform: translateY(-50%);
  font-size: 0.9375rem;
  line-height: 1;
}

.input-group-prepend .btn,
.input-group-prepend .input-group-text,
.input-group-append .btn,
.input-group-append .input-group-text {
  margin: 0 -1px;
  border-radius: 1.25rem;
  border-color: #ced4da;
  padding: 0.25rem 1rem;
}

.input-group-prepend .form-control {
  padding-left: 2.5rem;
}

.input-group-prepend .icon {
  left: 0;
  margin-left: 1rem;
}

.input-group-prepend .btn,
.input-group-prepend .input-group-text {
  border-radius: 1.25rem;
}

.input-group-append .form-control {
  padding-right: 2.5rem;
}

.input-group-append .icon {
  right: 0;
  margin-right: 1rem;
}

.input-group--lg .form-control,
.input-group--lg .btn {
  border-radius: 1.75rem;
}

.has-error {
  color: #df3603;
}

.has-success {
  color: #28a745;
}

.custom-control {
  font-size: 14px;
  line-height: 1.71429;
  min-height: 1.71429em;
  padding-left: 1.71429em;
  margin-bottom: 0;
  color: #666666;
}

.custom-control .custom-control-label:before {
  width: 1.14286em;
  height: 1.14286em;
  box-shadow: none !important;
  cursor: pointer;
}

.has-error .custom-control .custom-control-label:before {
  border-color: #df3603;
}

.custom-control .custom-control-label:before .has-success {
  border-color: #28a745;
}

.custom-checkbox .custom-control-label::before {
  border: 2px solid #ced4da;
  border-radius: 0;
  background-color: transparent !important;
}

.custom-checkbox .custom-control-label::after {
  width: 0.92857em;
  height: 0.42857em;
  margin: 0.35714em 0 0 0.14286em;
  transform: rotate(-45deg);
  background-repeat: no-repeat;
  background-position: 0px -10px, 0px 100%;
  background-size: 2px 100%, 0 2px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-position: 0px 0px, 0px 100%;
  background-size: 2px 100%, 100% 2px;
  transition: background-position 50ms ease-in, background-size 50ms ease-out 50ms;
}

.custom-radio .custom-control-label:before, .custom-radio .custom-control-label:after {
  top: 0;
  bottom: 0;
}

.custom-radio .custom-control-label:before {
  margin: auto;
}

.custom-radio .custom-control-label::after {
  width: 6px;
  height: 6px;
  margin: auto 5px;
  border-radius: 50%;
  background-color: #fff;
  transform: scale(0);
  transition: transform 120ms;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  transform: scale(1);
}

.form-control + .select2-container.select2-container--default .select2-selection--single {
  padding-right: 1.25rem;
}

.form-select .select2-selection--single {
  position: relative;
  overflow: hidden;
}

.form-select .select2-selection--single .select2-selection__rendered {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

.form-select .select2-selection--single .select2-selection__arrow {
  width: 1.53846em;
  height: auto;
  display: flex;
  align-items: center;
  margin: 0;
  top: 0;
  bottom: 0;
  margin: -1px;
  margin-left: auto;
  background-color: #fff;
}

.form-select .select2-selection--single .select2-selection__arrow:after {
  content: "\f107";
  font: normal normal normal 16px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.form-select .select2-selection--single .select2-selection__arrow b {
  display: none;
}

.select-dropdown .form-select .select2-selection--single {
  display: inline-flex;
}

.form-select .select2-container--open .select2-selection--single .select2-selection__arrow:after {
  transform: rotate(-180deg);
}

.select2-dropdown {
  border: none;
  border-radius: 0;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.select2-dropdown .select2-search {
  position: absolute;
  width: 100%;
  padding: 0.5em 0;
  transform: translateY(-100%);
  background-color: #ffffff;
  border-bottom: 1px solid #fd962c;
}

.select2-dropdown .select2-search .select2-search__field {
  padding: 0;
  border: none;
}

.select2-dropdown .select2-results__option {
  padding-left: 15px;
  transition: 200ms;
  font-size: inherit;
  line-height: inherit;
}

.select2-dropdown .select2-results__option[aria-selected=true] {
  background-color: #fff;
}

.select2-container .select2-dropdown .select2-results__option--highlighted[aria-selected] {
  color: #fff;
}

.select2-dropdown--above {
  margin-top: 0;
  border-bottom-width: 0;
}

.select2-dropdown--above .select2-search {
  top: auto;
  bottom: 0;
  width: 100%;
  transform: translateY(100%);
}

.select2-dropdown--below {
  border-top-width: 0;
}

.flatpickr-calendar {
  top: 0;
  position: absolute;
  display: none;
}

.progress {
  position: relative;
  height: 1.25rem;
  margin-right: 1rem;
  flex-grow: 1;
  background-color: #fff;
  z-index: 1;
}

.progress:after {
  content: '';
  border: 1px solid #dcdcdc;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.progress--sm {
  height: 0.4375rem;
  border-radius: 0.25rem;
}

.progress--sm:after {
  border-radius: 0.25rem;
}

.progress--lg {
  height: 1.25rem;
  border-radius: 0.625rem;
}

.progress--lg:after {
  border-radius: 0.625rem;
}

.progress-bar {
  text-align: center;
  transition: width 1s ease;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 550px;
  }
}

.modal--full {
  padding-right: 0 !important;
}

.modal--full .modal-dialog {
  max-width: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
}

.modal--full .modal-content {
  height: 100%;
  border: none;
  box-shadow: none;
}

.modal-content {
  border-radius: 0;
  border-color: #999999;
  background-color: #fff;
}

.close {
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  color: inherit;
  text-shadow: none;
}

body.modal-open,
.modal-backdrop {
  -webkit-overflow-scrolling: touch;
}

/**
* Blocks
*/
.modal-account {
  opacity: 0;
  z-index: 1060;
}

.modal-account.show {
  opacity: 1;
  transition: opacity 120ms ease-in;
}

.modal-account .modal-header {
  position: relative;
  display: block;
  padding: 0;
  border: none;
  border-radius: 0;
  overflow: hidden;
}

.modal-account .close {
  position: absolute;
  top: 0;
  right: 0;
  width: 3rem;
  height: 100%;
  padding: 0;
  margin: 0;
  border-radius: 0;
  transition: background-color 120ms, opacity 120ms;
}

html.rtl .modal-account .close {
  right: auto;
  left: 0;
  margin: 0;
}

.modal-account .close:hover {
  color: #1dc6c8;
}

.modal-account__tabs {
  display: flex;
  margin: -1px -1px 0;
}

.modal-account__tabs .nav-link {
  padding: 1rem 1.25rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 0 !important;
  border: 1px solid #dcdcdc;
  color: #444444;
  transition: 120ms linear;
}

.modal-account__tabs .nav-link.active {
  cursor: default;
}

.modal-account .modal-body {
  padding: 2.5rem 2.5rem 0;
}

.modal-account .modal-footer {
  padding: 1rem 0;
}

.modal-account__form {
  opacity: 0;
}

.modal-account__form .form-group {
  transition: 0s 0.3s;
}

.tab-pane.show .modal-account__form {
  opacity: 1;
  transition: opacity 0.3s ease-in-out 150ms;
}

.tab-pane.show .modal-account__form .form-group {
  margin-bottom: 1.25rem;
  transition: margin-bottom 0.3s ease-in-out;
}

.modal-account__form .help-block {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

@media (max-width: 767px) {
  .modal-account {
    display: block !important;
    opacity: 1;
    transition: transform 300ms linear;
    transform: translateX(-100%);
  }
  html.rtl .modal-account {
    transform: translateX(100%);
  }
  .modal-account.show {
    transform: translateX(0) !important;
    transition: transform 300ms linear;
  }
  .modal-account .modal-dialog {
    margin: 0;
    align-items: flex-start;
    height: 100%;
  }
  .modal-account .modal-content {
    min-height: 100%;
  }
}

.social-sign__list {
  margin: 0 -10px;
}

.social-sign__list > * {
  display: flex;
  padding: 0 10px;
  margin-bottom: 1.25rem;
  flex-grow: 1;
}

.social-sign__list .nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: inherit;
  transition: background-color 120ms ease;
}

.social-sign__list .nav-icon {
  max-width: 2.50rem;
  line-height: 1;
}

@media (min-width: 768px) {
  .social-sign__list .nav-icon {
    max-width: 3.25rem;
  }
  .social-sign__list .nav-link {
    min-height: 4.375rem;
    padding: 0.25rem 1.25rem;
    border: 1px solid #ced4da;
    border-radius: 34px;
  }
}

.modal-map {
  padding-right: 0 !important;
  opacity: 0;
  transition: opacity 300ms;
}

.modal-map.show {
  opacity: 1;
  transition: opacity 300ms;
}

.modal-map .modal-dialog {
  max-width: 100%;
  margin: 0;
}

.modal-map .modal-content {
  height: 100vh;
  border: none;
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.modal-map .map-contain {
  flex-grow: 1;
}

/**
 * Site Header
 */
.page-header {
  padding-bottom: 0;
  margin: 0;
  border-bottom: none;
}

.page-header__top {
  position: relative;
  transition: box-shadow 150ms ease;
  z-index: 10;
}

.page-header__top.fixed-top {
  position: fixed;
}

.page-header__top.fixed-top-active {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.24);
}

body.menu-open .page-header__top {
  z-index: 1041;
}

.page-header__top > .container {
  display: flex;
  max-width: 1140px;
}

.page-header__top > .container > .row {
  flex-grow: 1;
  min-height: 4rem;
}

.page-header__top .nav-panel {
  flex-wrap: nowrap;
  margin-left: auto;
  margin-right: -15px;
}

.page-header__top .nav-panel .nav-item {
  display: flex;
}

.page-header__top .nav-panel .nav-link {
  display: flex;
  align-items: center;
  padding: 0.50rem 1rem;
}

.page-header__call {
  color: #666666;
}

.page-header__call .icon-call {
  font-size: 2rem;
}

@media (max-width: 575px) {
  .page-header__top {
    height: 50px;
  }
}

.navbar-logo {
  color: #666666;
}

.navbar-logo:hover {
  text-decoration: none;
}

.navbar-search .form-control {
  font-weight: normal;
}

html.rtl .navbar-search .form-control {
  padding-left: 0 !important;
  padding-right: 1rem !important;
}

.navbar-search .btn {
  padding: 0 1rem;
  color: #999999;
}

html.rtl .navbar-search .btn {
  border-right: none;
}

html:not(.rtl) .navbar-search .btn {
  border-left: none;
}

.nav-panel .nav-link {
  color: inherit;
  border: none;
  border-radius: 0;
  text-transform: uppercase;
  white-space: nowrap;
  text-align: left;
  transition: background-color 300ms;
}

.nav-panel .nav-link .icon {
  vertical-align: top;
  font-size: inherit;
  color: #adb5bd;
}

.nav-panel .nav-link span {
  display: inline-block;
  vertical-align: top;
}

.main-nav .nav-panel .nav-item {
  width: 100%;
}

.main-nav .nav-panel .nav-link {
  width: 100%;
  display: flex;
  padding: 1rem 0.50rem;
}

.main-nav .nav-panel .nav-link .icon {
  width: 34px;
  padding-right: 0.50rem;
  margin: 0 !important;
  text-align: center;
}

html.rtl .main-nav .nav-panel .nav-link .icon {
  padding-right: 0;
  padding-left: 0.50rem;
}

html:not(.rtl) .nav-panel .dropdown-menu {
  top: 100% !important;
  left: auto !important;
  right: 0;
  transform: none !important;
}

html.rtl .nav-panel .dropdown-menu {
  right: auto;
}

/**
* Navigation menu
*/
.main-nav {
  position: relative;
  z-index: 3;
}

.main-nav.show {
  z-index: 1041;
}

.main-nav .container {
  max-width: 1140px;
}

.main-nav__list .nav-link {
  padding: 0.50rem;
  font-size: 13px;
  transition: background-color 300ms;
}

.main-nav__list .nav-link span {
  border-bottom: 1px solid transparent;
}

.main-nav__list .dropdown > .nav-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-nav__list .dropdown-menu {
  float: none;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
}

.main-nav__list .dropdown-menu .nav-item {
  border-bottom: 1px solid #dcdcdc;
}

.main-nav__list .dropdown-menu .nav-item:last-child {
  border-bottom: none;
}

.main-nav__list .dropdown-menu .nav-link {
  color: inherit;
  background-color: transparent;
}

.main-nav__search {
  padding: .5rem 0 .5rem .5rem;
  display: flex;
  align-items: center;
  width: 100%;
}

.main-nav__search .navbar-search {
  width: 100%;
}

.main-nav__search .navbar-search .form-control {
  padding: 0.50rem;
  margin: 0 1px;
}

html.rtl .main-nav__search .navbar-search .form-control {
  margin: 0 1px;
}

.main-nav__search .navbar-search .form-control, .main-nav__search .navbar-search .btn {
  border: none;
  border-radius: 0;
}

.main-nav__search .navbar-search .btn {
  padding: 0;
  width: 34px;
}

.main-nav__search .navbar-search .input-group-append {
  order: -1;
}

@media (min-width: 992px) {
  .main-nav {
    padding: 0;
  }
  .main-nav__list {
    margin-top: 0;
  }
  .main-nav__list > .dropdown > .dropdown-menu {
    transform: translateX(10px);
  }
  .main-nav__list > .dropdown:hover > .dropdown-menu {
    transform: translateX(0);
  }
  .main-nav__list .dropdown:hover > .dropdown-menu {
    opacity: 1;
    visibility: visible;
    transition: 300ms ease, visibility 0s 0s;
  }
  .main-nav__list .dropdown-menu {
    min-width: 180px;
    display: block !important;
    height: auto !important;
    visibility: hidden;
    opacity: 0;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    transition: 300ms ease-in, visibility 0s 300ms;
  }
  .main-nav__list .dropdown-menu > .dropdown:hover > .dropdown-menu {
    transform: translateY(0);
  }
  .main-nav__list .dropdown-menu .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    transform: translateY(10px);
  }
  html.rtl .main-nav__list .dropdown-menu .dropdown-menu {
    left: -100%;
  }
}

@media (max-width: 991px) {
  .main-nav {
    display: block !important;
    position: fixed;
    left: 20px;
    left: 0;
    width: 100%;
    max-width: 240px;
    height: calc(100% - 50px) !important;
    z-index: 1050;
    overflow: hidden;
    overflow-y: auto;
    transform: translateX(-100%);
  }
  html.rtl .main-nav {
    left: auto;
    right: 20px;
    right: 0;
    transform: translateX(100%);
  }
  body.menu-open .main-nav {
    transform: translateX(-20px);
    transform: translateX(0);
  }
  html.rtl body.menu-open .main-nav {
    transform: translateX(20px);
    transform: translateX(0);
  }
  .main-nav__container {
    min-height: 100%;
  }
  .main-nav__container > .container {
    padding: 0;
  }
  .main-nav__list .nav-item {
    width: 100%;
    border-bottom: 1px solid #dcdcdc;
  }
  .main-nav__list .dropdown-menu {
    position: relative;
    top: auto;
    left: auto !important;
    min-width: 100%;
  }
}

.btn-toggle-nav {
  position: fixed;
  left: -20px;
  padding-left: 20px;
  z-index: 1041;
  height: 100%;
  width: 40px;
  padding-bottom: 50px;
  transform: translateX(0px);
}

.btn-toggle-nav .icon-bar {
  transform: rotate(-90deg);
}

html.rtl .btn-toggle-nav {
  left: auto;
  right: -20px;
  padding-left: 0;
  padding-right: 20px;
}

.menu-open .btn-toggle-nav {
  transform: translateX(240px);
}

html.rtl .menu-open .btn-toggle-nav {
  transform: translateX(-240px);
}

/**
 * Flight searchbar
 */
.flights-search__content {
  position: relative;
  z-index: 1;
}

.flights-search__form .row {
  margin: 0 -7px;
}

.flights-search__form .row > * {
  padding: 0 7px;
}

.flights-search__btn {
  width: 35px;
  height: 35px;
  padding: 0.25rem;
  margin: 0 auto;
  border-radius: 1.25rem;
}

@media (min-width: 992px) {
  .flights-search__btn {
    max-width: 35px;
  }
}

/**
 * Breadcrumbs
 */
.breadcrumb-nav {
  position: relative;
  background-color: #e8e5df;
  z-index: 1;
}

.breadcrumb-nav .navbar-brand {
  padding: 1rem;
  margin: 0;
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.breadcrumb {
  padding: 1.25rem 0;
  margin-bottom: 0;
  background-color: transparent;
  border-radius: 0;
  color: #666666;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding: 0 0.75rem;
  color: inherit;
  content: "|";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #666666;
}

/**
 * Pagination
 */
.pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.pagination .page-item {
  margin: 0 0.25rem;
}

html.rtl .pagination .page-item .fa {
  transform: scaleX(-1);
}

.page-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50% !important;
  padding: 4px;
  margin: 0;
  line-height: 1;
  transition: 200ms ease;
}

.page-content {
  position: relative;
  padding-bottom: 3.75rem;
  border-bottom: 3px solid #fd962c;
  background-color: #e8e5df;
  z-index: 1;
}

.page-content.modal-open {
  position: static;
}

.category-content__items {
  margin-bottom: 0.875rem;
}

.category-content__items > * {
  margin-bottom: 1.875rem;
}

.category-content__items:not(.row-list) .banner-grid {
  order: 1;
}

.section-header {
  padding-top: 4.0625rem;
  margin-bottom: 2.25rem;
}

.section-header .h2 {
  font-size: 1.5rem;
}

.section-header__stars {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #d1d1d1;
  font-size: 14px;
}

.section-header__stars:before, .section-header__stars:after {
  content: '';
  width: 4.375rem;
  border-top: 1px solid;
}

.section-header__stars:before {
  margin-right: 1rem;
}

.section-header__stars:after {
  margin-left: 1rem;
}

.section-header__stars i.fa {
  margin: 4px;
  align-self: flex-end;
}

.section-header__stars i.fa.center {
  font-size: 20px;
  align-self: center;
}

.page-footer {
  position: relative;
}

.page-footer.sticky {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

@media (max-width: 991px) {
  body {
    padding-left: 20px;
  }
  html.rtl body {
    padding-left: 0;
    padding-right: 20px;
  }
}

@media (max-width: 767px) {
  main {
    margin-bottom: 0 !important;
  }
  .page-footer.sticky {
    position: relative;
  }
}

/**
 * Intro home
 */
body.load .intro {
  min-height: calc(100vh);
}

.intro__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #fff;
}

.intro__bg.sticky {
  position: fixed;
}

.intro__bg .over {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 3;
}

.intro__bg .scene {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: opacity 1s linear 1s;
}

body.load .intro__bg .scene {
  opacity: 0;
}

.intro__bg .swiper-container {
  height: 100%;
}

.intro__bg .swiper-slide .img-cover {
  height: calc(100% + 100px);
}

.intro__bg .swiper-slide.swiper-slide-prev .img-cover, .intro__bg .swiper-slide.swiper-slide-active .img-cover {
  animation: coverTop 32s linear;
}

@keyframes coverTop {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100px);
  }
}

.intro__desc {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  padding: 15vh 0;
  font-size: 1.25rem;
  color: #fff;
  font-family: "Poppins", sans-serif;
  opacity: 1;
  transition: opacity 1s ease 1s;
}

body.load .intro__desc {
  opacity: 0;
}

.intro__desc .container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 100;
}

.intro__desc .container > .row {
  flex-grow: 1;
}

.intro__desc .swiper-slide {
  opacity: 0 !important;
}

.intro__desc .swiper-slide.swiper-slide-active {
  opacity: 1 !important;
}

.intro__caption {
  font-weight: normal;
  font-size: inherit;
}

.intro__title {
  font-size: 90px;
  line-height: 1.05;
}

.intro__content {
  width: 100%;
  padding-top: 2.50rem;
  opacity: 1;
  z-index: 1;
  transition: opacity 1s ease 1s;
}

body.load .intro__content {
  opacity: 0;
}

.intro__search {
  position: relative;
  z-index: 2;
  margin-bottom: 20px;
}

.intro__hotels {
  position: relative;
  padding-top: 50px;
}

.intro__hotels-controls > * {
  top: 0;
  position: absolute;
}

.intro__hotels-controls > *:first-child {
  left: 1rem;
}

.intro__hotels-controls > *:last-child {
  right: 1rem;
}

.intro__btn-scroll {
  position: absolute;
  display: none;
  bottom: 0;
  left: 50%;
  width: 40px;
  height: 70px;
  margin-left: -20px;
  color: #fff;
  transition: opacity .3s;
  z-index: 2;
  cursor: pointer;
}

.intro__btn-scroll span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);
  animation: btnScrollAnim 1.50s infinite;
}

@keyframes btnScrollAnim {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

@media (max-width: 1199px) {
  .intro__desc {
    position: relative;
  }
  .intro__content {
    min-height: auto !important;
  }
  .intro__title {
    font-size: 7.5vw;
  }
  .intro__btn-scroll {
    display: block;
  }
}

@media (max-width: 575px) {
  .intro__title {
    font-size: 48px;
  }
}

.card-intro {
  position: relative;
  min-height: 250px;
  padding-top: 69.4%;
  font-size: 14px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff;
}

.card-intro .card-price {
  right: 0;
}

.card-intro__footer {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 15px;
}

.card-intro__footer .h4 {
  font-weight: inherit;
  margin-bottom: 0;
  height: 1.25rem;
  font-size: 1.125rem;
  color: inherit;
  overflow: hidden;
}

.card-intro__local {
  margin-bottom: 8px;
}

.card-intro__local .link {
  color: inherit;
}

.card-intro__rating .br-widget {
  height: auto;
}

.card-intro__rating .br-widget a {
  font-size: 14px;
}

/**
 * Intro pages
 */
.intro-page {
  position: relative;
  height: 45vh;
  z-index: 1;
}

.intro-page .container {
  position: relative;
  z-index: 1;
}

/**
 * Intro Searchbar Tabs
 */
.search-hotels__tabs {
  position: relative;
  display: inline-flex;
  width: 100%;
  text-align: center;
}

.search-hotels__tabs .nav-item {
  flex-grow: 1;
  display: flex;
  margin-bottom: 0;
  width: 33%;
}

.search-hotels__tabs .nav-link {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.50rem;
  font-weight: normal;
  border-radius: 0;
  border-bottom-width: 0;
  color: inherit;
  transition: 120ms linear;
}

.search-hotels__tabs .nav-link.active {
  cursor: default;
}

@media (min-width: 768px) {
  .search-hotels__tabs {
    width: auto;
  }
  .search-hotels__tabs .nav-item {
    width: auto;
  }
  .search-hotels__tabs .nav-link {
    font-size: 1rem;
    padding: 0.50rem 1.50rem;
  }
}

/**
 * Intro Searchbar Form
 */
.search-hotels__form {
  position: relative;
  padding: 1.25rem 0.9375rem 0.5rem;
  font-size: 14px;
  color: #212529;
  background-color: #fff;
}

.search-hotels__form:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 1px solid #fff;
}

.search-hotels__form .row > *:not(.border-0):after {
  content: '';
  position: absolute;
  top: 6px;
  right: 0;
  height: 3.125rem;
  border-right: 1px solid #dcdcdc;
}

.search-hotels__form .form-group {
  margin-bottom: 1rem;
}

.search-hotels__form .form-group > * {
  margin-bottom: 0.50rem;
}

.search-hotels__form .form-group-date {
  width: 100%;
  max-width: 340px;
}

.search-hotels__form .form-group-date > .d-inline-block {
  width: calc((50% - 2px) - 1.5rem);
}

.search-hotels__form .input-date-custom {
  min-width: 105px;
}

.search-hotels__form .label-text {
  display: block;
  font-size: 15px;
}

.search-hotels__form .select-local {
  flex-grow: 1;
  border-radius: 4px;
}

.search-hotels__form .select-local .icon-label {
  padding: 0.15em 0.25em;
  font-size: 20px;
}

.search-hotels__form .select-local .select2-selection--single {
  height: 28px;
  padding: 2px 0.25rem;
  border: none;
  font-style: italic;
  font-size: inherit;
  font-weight: 600;
  color: inherit;
  line-height: 22px;
  overflow: hidden;
}

.search-hotels__form .select-local .select2-selection--single .select2-selection__rendered {
  display: inline;
  padding: 0;
  line-height: inherit;
  white-space: normal;
  background-color: transparent;
  border-bottom: 1px dotted transparent;
}

.search-hotels__form .select-local .select2-selection--single .select2-selection__clear {
  padding-left: 0.5rem;
  opacity: 0;
}

.search-hotels__form .select-local .select2-selection--single .select2-selection__arrow {
  display: none;
}

.search-hotels__form .select-local .select2-dropdown {
  min-width: 220px;
}

.search-hotels__form .select-local .select2-search {
  padding: 0.25em;
}

.search-hotels__form .form-control.date {
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: inherit;
  border: none;
  border-bottom: 1px solid transparent;
  border-radius: 0;
  background-color: transparent;
  color: inherit;
}

.search-hotels__form .form-control.date:focus ~ .date {
  border-bottom: 1px dotted #fd962c;
}

.search-hotels__form .icon-label {
  padding: 2px 0 0 2px;
  font-size: 1.25rem;
}

.search-hotels__form .icon-calendar {
  font-size: 1.25rem;
}

.search-hotels__form [type=submit] {
  width: 170px;
  margin: auto;
}

@media (min-width: 576px) {
  .search-hotels__form .form-group {
    margin-bottom: 0.50rem;
  }
}

/**
* Cards
*/
.card {
  border-radius: 0;
  border-color: #dcdcdc;
}

.card-price {
  position: absolute;
  top: 1.25rem;
  right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  min-width: 105px;
  font-size: 13px;
  text-align: center;
  border-radius: 0.5rem 0 0 0.5rem;
  color: #555555;
  background-color: #fff;
  z-index: 1;
}

.card-price .count {
  font-weight: 700;
  font-size: 15px;
}

html.rtl .card-price {
  right: auto;
  left: 0;
  border-radius: 0 0.5rem 0.5rem 0;
}

.card-hover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s, visibility 0s 0.40s;
  text-align: center;
  z-index: 1;
}

.card-hover h3 {
  margin-bottom: 1.25rem;
  font-size: 1.25rem;
}

.card-hotel:hover .card-hover,
.card-intro:hover .card-hover {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.4s, visibility 0s 0s;
}

.card-hover__view {
  max-width: 146px;
  width: 100%;
}

.card-hotel {
  position: relative;
  display: block;
  font-size: 14px;
  border-radius: 0;
}

.card-hotel__img {
  position: relative;
  flex-grow: 1;
  padding-top: calc(100% - 25px);
  z-index: 1;
}

.card-hotel .card-img-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.card-hotel__bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 1rem 0.85rem;
  background-color: #fff;
  z-index: 1;
}

.card-hotel__bottom h4 {
  font-weight: 500;
  font-size: 1.25rem;
}

.card-hotel__local {
  color: #999999;
}

.card-hotel__local .link {
  color: inherit;
}

.card-service {
  padding: 0 1rem;
}

.card-service .card-header {
  padding: 1rem 0 1.25rem;
  background-color: transparent;
}

.card-service h4 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 1.25rem;
}

.card-service .card-body {
  padding: 1.25rem 0;
}

.booking-card {
  margin-bottom: 2rem;
}

.booking-card__form .form-group {
  margin-bottom: 2rem;
}

.bx2dp {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.bx4dp {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.bx8dp {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

/**
 * Category page, items, list & grid, banners
 */
.product {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  min-width: 180px;
  padding: 0 1rem;
  border-radius: 0;
  border: 1px solid #dcdcdc;
}

.product hr {
  margin: 8px 0;
}

.product__special {
  position: absolute;
  top: 1rem;
  right: -1px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 6.6875rem;
  min-height: 2.25rem;
  padding: 0.5rem;
  border-radius: 6px 0 0 6px;
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  text-transform: uppercase;
  z-index: 2;
}

html.rtl .product__special {
  right: auto;
  left: -1px;
  border-radius: 0 6px 6px 0;
}

.product__img-top {
  position: relative;
  min-height: 200px;
  margin: 0 -1rem 1rem;
  overflow: hidden;
  z-index: 1;
}

.product__img-top img {
  transform: scale(1);
  transition: 120ms linear;
}

.product__img-top:hover img {
  transform: scale(1.25);
}

.product__title {
  margin-bottom: 6px;
  color: inherit;
  font-size: 1.125rem;
  line-height: 1.33333;
}

.product__title a {
  color: inherit;
}

.product__rating {
  font-size: 12px;
}

.product__stat {
  margin-bottom: calc(1rem - 4px);
  color: #999999;
}

.product__stat > * {
  margin-bottom: 4px;
}

.product__stat > * > * {
  display: inline-block;
  vertical-align: middle;
}

.product__stat > *:last-child {
  color: #666666;
}

.product__price .sale {
  font-weight: normal;
  text-decoration: line-through;
  color: #666666;
  white-space: nowrap;
}

.product__price .cost {
  font-weight: 600;
  font-size: 1.125rem;
  white-space: nowrap;
}

.product__price + hr {
  margin-bottom: 12px;
}

.product__body {
  flex-grow: 1;
}

.product__body + hr {
  margin: 12px 0 18px;
}

.product__desc p {
  margin-bottom: 0;
}

.product__footer {
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 0.625rem 1rem;
}

.product__btn-book {
  width: 100%;
  max-width: 136px;
  padding: 0.75rem;
}

.product:not(.product--list) .product__footer .product__price {
  display: none !important;
}

.product--list {
  padding: 1rem 0;
}

@media (min-width: 576px) {
  .product--list {
    flex-direction: row;
  }
}

.product--list .product__img-top {
  margin: -1rem 0 1rem;
}

.product--list .product__body {
  padding: 0 10px;
}

.product--list .product__body .product__price {
  display: none !important;
}

.product--list .product__body .product__price + hr {
  display: none;
}

.product--list .product__body + hr {
  display: none;
}

.product--list .product__desc {
  position: relative;
  max-height: 76px;
  padding-bottom: 19px;
  overflow: hidden;
}

.product--list .product__desc a {
  display: flex;
  align-items: flex-end;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.product--list .product__desc a:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, transparent 80%, white 80%);
  z-index: -1;
}

.product--list .product__price {
  margin-bottom: 8px;
}

.product--list .product__footer {
  flex-direction: column;
  padding: 0 0.625rem;
}

@media (min-width: 576px) {
  .product--list .product__special {
    right: auto;
    left: -1px;
    border-radius: 0 calc(0.5rem + 3px) calc(0.5rem + 3px) 0;
  }
  html.rtl .product--list .product__special {
    left: auto;
    right: -1px;
    border-radius: calc(0.5rem + 3px) 0 0 calc(0.5rem + 3px);
  }
  .product--list .product__img-top {
    min-width: 31.25%;
    margin: -1rem 0;
  }
  .product--list .product__body {
    padding-left: calc(2.5% + 10px);
    padding-right: calc(0.6% + 10px);
    border-right: 1px solid #dcdcdc;
  }
  html.rtl .product--list .product__body {
    border-right: none;
    border-left: 1px solid #dcdcdc;
  }
  .product--list .product__footer {
    width: 18.78788%;
    min-width: 120px;
  }
  .product--list .product__footer .product__price {
    flex-grow: 1;
    flex-direction: column;
    margin: 0;
  }
  .product--list .product__footer .product__price > li:first-child {
    display: block;
    width: 80px;
    text-align: center;
  }
  .product--list .product__footer .product__price > li:last-child {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .product--list .product__desc {
    max-height: 95px;
  }
}

.banner-list {
  position: absolute;
  right: 0;
  z-index: -1;
  opacity: 0;
}

.row-list .banner-grid {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.row-list .banner-list {
  position: static;
  right: 0;
  z-index: 1;
  opacity: 1;
}

.product-banner {
  opacity: 0;
}

.product-banner > .row {
  flex-grow: 1;
}

.swiper-slide-active .product-banner {
  opacity: 1;
}

.product-banner h4.h4 {
  font-size: 1.125rem;
}

/*
 * Category Sortbar
 */
.sortbar {
  padding: 0;
  margin-bottom: 1.875rem;
  border: 1px solid #ced4da;
  font-size: 14px;
  color: #666666;
  background-color: #fff;
}

.sortbar .navbar-brand,
.sortbar .nav-link {
  background-color: #fff;
}

.sortbar .navbar-brand:hover,
.sortbar .nav-link:hover {
  background-color: #f8f9fa;
}

.sortbar .navbar-brand:active, .sortbar .navbar-brand:focus,
.sortbar .nav-link:active,
.sortbar .nav-link:focus {
  background-color: #e9ecef;
}

.sortbar .navbar-brand:after,
.sortbar .nav-link:after {
  margin: 0;
}

.sortbar .navbar-brand {
  padding: 0 1.875rem 0 1rem;
  margin: 0;
  color: #999999;
}

html.rtl .sortbar .navbar-brand {
  padding: 0 1rem 0 1.875rem;
}

.sortbar .nav-link {
  display: flex;
  align-items: center;
  font-size: inherit;
  color: inherit;
  white-space: nowrap;
}

.sortbar__sort {
  flex-grow: 1;
  padding: 0.5625rem 0px;
}

.sortbar__sort .nav-link {
  border-right: 1px solid #999999;
  padding: 0.375rem 0.875rem;
}

.sortbar__sort .nav-link:after {
  display: inline-block;
  margin-left: 1rem;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\f175';
}

html.rtl .sortbar__sort .nav-link:after {
  margin-left: 0;
  margin-right: 1rem;
}

.sortbar__sort .nav-link.asc:after {
  content: '\f176';
}

.sortbar__sort .nav-link.desc:after {
  content: '\f175';
}

html.rtl .sortbar__grid {
  padding-right: 0;
}

.sortbar__grid .nav-link {
  padding: 0 1.25rem;
  font-size: 1.25rem;
  line-height: 1;
}

@media (min-width: 992px) {
  .sortbar .navbar-brand {
    pointer-events: none;
  }
}

@media (max-width: 991px) {
  .sortbar .navbar-brand {
    padding: 0 1.75rem;
  }
  html.rtl .sortbar .navbar-brand {
    padding: 0 1.75rem;
  }
}

@media (max-width: 767px) {
  .sortbar > * {
    flex-grow: 1;
    width: 25%;
    padding: 0;
    margin: 0;
  }
  .sortbar > * > * {
    flex-grow: 1;
  }
  html.rtl .sortbar > * {
    padding: 0;
  }
  .sortbar .navbar-brand,
  .sortbar .nav-link {
    justify-content: center;
    padding: 1rem 0.8rem;
  }
  .sortbar .navbar-brand span,
  .sortbar .nav-link span {
    display: none;
  }
  .sortbar__sort {
    order: 1;
  }
  .sortbar__sort .nav-link {
    display: none;
    border-right: 0;
  }
  .sortbar__sort .nav-link:after {
    display: none;
  }
  .sortbar__sort .nav-link.asc:after, .sortbar__sort .nav-link.desc:after {
    display: block;
  }
  .sortbar__sort .nav-link.asc i, .sortbar__sort .nav-link.desc i {
    display: none;
  }
  .sortbar__grid {
    width: 50%;
  }
}

/**
 * Hotel, navigation, cards, guest rooms
 */
.comment__avatar, .comment-post__avatar {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  margin-right: 1.25rem;
  border: 1px solid #dcdcdc;
  background-color: #cccccc;
}

html.rtl .comment__avatar, html.rtl .comment-post__avatar {
  margin-right: 0;
  margin-left: 1.25rem;
}

.hotel-nav {
  margin-bottom: 1.25rem;
}

.hotel-nav__menu > * {
  flex-grow: 1;
}

.hotel-nav__menu .nav-link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  padding: 0.375rem 0.875rem;
  border-radius: 0;
  border: none;
  text-align: center;
}

.hotel-nav__menu .nav-link > * {
  display: table-cell;
  vertical-align: middle;
  max-width: 130px;
}

.hotel-card {
  padding: 1.25rem;
  margin-bottom: 1.875rem;
  border: 1px solid #dcdcdc;
  background-color: #f8f9fa;
}

.hotel-card h3 {
  font-weight: normal;
  font-size: 1.25rem;
}

.hotel-card h4 {
  font-weight: normal;
  font-size: 1.125rem;
  font-family: "Poppins", sans-serif;
}

.hotel-card h5 {
  margin-bottom: 1.5rem;
  font-weight: 700;
  font-size: 13px;
  line-height: 1.25rem;
}

.hotel-card h5 .icon {
  font-size: 1.25rem;
  line-height: 1;
}

.hotel-title i.icon-label {
  font-size: 17px;
}

.hotel-title .local {
  color: #666666;
}

.hotel-title .local > * {
  vertical-align: middle;
}

.hotel-stats {
  line-height: 1.2;
  color: #444444;
}

.hotel-stats__view > * {
  margin-bottom: 10px;
}

.hotel-stats__view .media-object {
  font-size: 1.5rem;
  color: #666666;
}

.hotel-stats__view .amount {
  color: #999999;
}

.hotel-stats__like > * {
  margin-bottom: 4px;
}

.hotel-stats__like .likes {
  white-space: nowrap;
  color: #888888;
}

.hotel-stats__progress {
  margin-bottom: 1.875rem;
}

.hotel-facilities > * {
  margin-bottom: 0.75rem;
  font-size: 13px;
}

.hotel-facilities > * .icon {
  font-size: 1.125rem;
}

.hotel-facilities > *:last-child i.bullet {
  display: none !important;
}

.hotel-service__title .icon {
  font-size: 1.25rem;
  color: #666666;
}

.hotel-service__title .h4 {
  margin-bottom: 0;
  font-size: 1.125rem;
  font-family: "Poppins", sans-serif;
}

.hotel-service__list {
  font-size: 13px;
}

.hotel-service__list > li {
  margin: 4px 0;
}

.hotel-service__list [class*=icon-check] {
  margin-right: 0.75rem;
  font-size: 14px;
  line-height: inherit;
}

html.rtl .hotel-service__list [class*=icon-check] {
  margin-right: 0;
  margin-left: 0.75rem;
}

.hotel-service__list .available {
  padding: 0.0625rem 0.5rem;
  font-size: 11px;
  border-radius: 6px;
}

.hotel-options {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5;
}

.hotel-options [class*=icon-check] {
  margin-right: 0.875rem;
  font-size: 1.5rem;
  color: #666666;
}

html.rtl .hotel-options [class*=icon-check] {
  margin-right: 0;
  margin-left: 0.875rem;
}

.hotel-status .form-group {
  position: relative;
  margin-bottom: 10px;
}

.hotel-status label,
.hotel-status .label-text {
  display: block;
  margin-bottom: 0;
}

.hotel-status .icon-calendar {
  display: none;
}

.hotel-status .form-control {
  padding: 0;
  font-weight: 700;
  font-size: 16px;
  border: none;
  border-bottom: 1px dotted;
  background-color: transparent;
  border-radius: 0;
  white-space: nowrap;
}

.hotel-status .form-control:hover ~ .form-control,
.hotel-status .form-control:focus ~ .form-control {
  opacity: 0.5;
}

.hotel-items {
  padding: 1.25rem;
}

.hotel-items .card-header {
  margin-bottom: 1.25rem;
  border: none;
  background-color: transparent;
}

.hotel-items .card-title {
  font-weight: 500;
  font-size: 1.25rem;
}

.hotel-items__check {
  text-align: center;
}

.hotel-items__check .price {
  font-size: 1.125rem;
}

.hotel-items__check .btn-order {
  max-width: 135px;
}

.hotel-checklist {
  padding-right: 13.33333%;
}

html.rtl .hotel-checklist {
  padding-right: 1.25rem;
  padding-left: 13.33333%;
}

.hotel-checklist > * {
  margin-bottom: 1.125rem;
}

@media (min-width: 576px) {
  .hotel-checklist > * > *:first-child {
    flex-shrink: 0;
    width: 165px;
    max-width: 165px;
  }
  .hotel-checklist > * > *:last-child {
    flex-grow: 1;
    width: 100%;
    max-width: 100%;
  }
}

.hotel-checklist h5.title {
  margin-bottom: 6px;
}

.hotel-checklist h5.title .icon {
  font-size: 1.125rem;
  line-height: inherit;
}

.hotel-checklist p {
  margin-bottom: 6px;
  line-height: 1.38462;
}

.hotel-review {
  padding: 1.5rem 1.875rem;
}

.hotel-review__total {
  max-width: 215px;
  padding-right: 1.5625rem;
}

.hotel-review__total p {
  margin-bottom: 10px;
}

.hotel-review__total hr {
  margin: 0.875rem 0px;
}

.hotel-review__total .total {
  max-width: 215px;
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 1;
  color: #999999;
}

.hotel-review__total .total__item:first-child {
  color: #333333;
}

.hotel-review__total .total__item:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 8px;
}

.hotel-review__total .reviews {
  color: #111111;
}

.hotel-review__progress {
  flex-grow: 1;
  max-width: 100%;
}

.hotel-review__progress .item {
  margin-bottom: 0.625rem;
}

.hotel-guests {
  padding: 0 1.875rem 2.25rem;
}

.hotel-guests__list {
  margin-bottom: 2.25rem;
}

.hotel-popular h3 {
  font-size: 1.25rem;
}

.comment {
  border-bottom: 1px solid #dcdcdc;
  padding: 1.25rem 0px;
}

.comment__right > * {
  margin-bottom: 5px;
}

.comment__right > *:last-child {
  margin-bottom: 0;
}

.comment__name {
  font-size: 1rem;
  color: #111111;
}

.comment__country {
  color: #666666;
}

.comment__like {
  color: #888888;
  white-space: nowrap;
}

.comment__like > * {
  margin-right: 0.875rem;
}

html.rtl .comment__like > * {
  margin-right: 0;
  margin-left: 0.875rem;
}

.comment__like .icon {
  margin-right: 4px;
  font-size: 12px;
  vertical-align: middle;
  line-height: inherit;
}

html.rtl .comment__like .icon {
  margin-right: 0;
  margin-left: 0.25rem;
}

.comment__level .badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  padding: 0;
  margin-right: 0.75rem;
  border-radius: 0.3125rem;
  font-size: 1rem;
  font-weight: 600;
}

html.rtl .comment__level .badge {
  margin-right: 0;
  margin-left: 0.75rem;
}

.comment__date {
  font-size: 11px;
}

.comment__desc > * {
  margin-bottom: 0.875rem;
}

.comment__desc > *:last-child {
  margin-bottom: 0;
}

.comment__desc .media-object {
  flex-shrink: 0;
  width: 2.25rem;
  margin-right: 0.75rem;
  font-size: 1.5rem;
  text-align: center;
}

html.rtl .comment__desc .media-object {
  margin-right: 0;
  margin-left: 0.75rem;
}

.comment__desc p {
  margin-bottom: 1.15rem;
  font-size: 13px;
  line-height: 1.46154;
}

.comment__desc p:last-of-type {
  margin-bottom: 0;
}

.comment__alert {
  padding: 12px;
  margin-top: 1.75rem;
  margin-bottom: 0;
  border: none;
}

.comment__alert .media-object {
  font-size: 1.5rem;
  color: #999999;
}

.comment__alert p {
  margin-bottom: 8px;
}

.comment__alert h4 {
  font-size: 1rem;
  line-height: 1.5;
  color: #333333;
}

@media (min-width: 576px) {
  .hotel-review__progress {
    padding: 0 3.63636%;
  }
  .comment__left {
    max-width: 220px;
  }
  .comment__content {
    position: relative;
    flex-grow: 1;
    max-width: 100%;
    padding: 0 3.63636%;
  }
  .comment__content:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-left: 1px solid #dcdcdc;
  }
  html.rtl .comment__content:before {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .hotel-items__check {
    border-left: 1px solid #dcdcdc;
  }
  html.rtl .hotel-items__check {
    border-left: none;
    border-right: 1px solid #dcdcdc;
  }
}

/**
 * Hotel check items
 */
.hotel-package:last-child {
  margin-bottom: 0 !important;
}

.hotel-package:last-child > hr.hr-bottom {
  opacity: 0;
}

.hotel-package__title {
  position: relative;
  padding-left: 0.75rem;
  margin: 12px 0 6px;
  font-size: 1.125rem;
}

.hotel-package__title:before {
  content: '';
  position: absolute;
  top: 0.375rem;
  left: 0;
  border-style: solid;
  border-width: 0.28125rem 0 0.28125rem 0.5rem;
  color: transparent;
}

.hotel-package__img {
  position: relative;
  overflow: hidden;
  height: 190px;
  z-index: 1;
}

.hotel-package__img img {
  transition: 0.25s;
}

.hotel-package__img:hover img {
  transform: scale(1.15);
}

.hotel-package__props {
  font-size: 13px;
}

.hotel-package__props > *.disabled {
  position: relative;
}

.hotel-package__props > *.disabled:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.hotel-package__props > *.disabled > *:not([data-toggle='popover']) {
  opacity: 0.50;
}

.hotel-package__props > *.disabled [data-toggle=popover] {
  position: relative;
  z-index: 1;
}

.hotel-package__props > *.disabled.rooms .title {
  opacity: 1;
}

.hotel-package__props .rooms {
  display: flex;
  align-items: center;
}

.hotel-package__props .form-select {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.25rem;
}

.hotel-package__props .form-select .select2-container {
  display: inline-block;
  min-width: 3.75rem;
}

.hotel-package__props .form-select .select2-container .select2-selection--single {
  height: auto;
  padding: 3px 14px 3px 4px;
  border: none;
  border-radius: 15px;
  font-size: inherit;
  text-align: center;
}

html.rtl .hotel-package__props .form-select .select2-container .select2-selection--single {
  padding-right: 4px;
  padding-left: 14px;
}

.hotel-package__props .form-select .select2-container .select2-selection--single .select2-selection__arrow {
  background-color: transparent;
}

.hotel-package__props .price {
  line-height: 1.2;
}

.hotel-package__props .price > * {
  vertical-align: baseline;
}

.hotel-package__props .sale {
  position: relative;
  padding-right: 6px;
  margin-right: 10px;
  color: #666666;
  border-bottom: 1px dotted;
  text-decoration: line-through;
  white-space: nowrap;
}

.hotel-package__props .sale i.hint {
  position: absolute;
  top: 0;
  right: -8px;
  margin-top: -8px;
}

.hotel-package__props .cost {
  font-size: 0.9375rem;
  white-space: nowrap;
}

.hotel-package__props .icon-user {
  font-size: 0.875rem;
}

.hotel-package__menu i {
  font-size: 1.25rem;
}

.hotel-package__more {
  padding-bottom: 1.125rem;
}

@media (max-width: 479px) {
  .hotel-package__row {
    text-align: center;
  }
  .hotel-package__row > * {
    flex-basis: 100%;
    max-width: 100%;
  }
  .hotel-package__props .rooms {
    justify-content: center;
  }
}

html.rtl .hotel-package__row > *:first-child {
  padding-right: 1rem !important;
}

/**
 * Hotel Gallery
 */
.hotel-gallery__carousel {
  min-height: 38vh;
  height: 41.66667vw;
  max-height: 500px;
  margin-bottom: 5px;
}

.hotel-gallery__thumbs .swiper-slide {
  width: 80px;
  height: 60px;
  border: 2px solid #fff;
}

.hotel-gallery__thumb {
  height: 100%;
  display: block;
  background-size: cover;
}

.hotel-gallery__arrow {
  display: block;
  position: absolute;
  top: calc(50% - 0.5em);
  width: 1em;
  height: 1em;
  font-size: 32px;
  line-height: 1;
  z-index: 1;
  cursor: pointer;
  opacity: 0.50;
  border-radius: 50%;
}

.hotel-gallery__arrow:first-of-type {
  left: 0.5rem;
}

.hotel-gallery__arrow:last-of-type {
  right: 0.5rem;
}

.hotel-gallery__arrow:hover {
  opacity: 1;
}

.hotel-gallery__arrow .icon {
  color: #fff;
}

/**
 * Lightbox for Gallery
 */
.blueimp-gallery .close {
  display: flex;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #fff;
  opacity: 1;
}

.blueimp-gallery .close:before {
  transform: rotate(-45deg);
}

.blueimp-gallery .close:after {
  transform: rotate(45deg);
}

.gallery-modal {
  font-size: 14px;
}

.gallery-modal a[data-toggle=collapse] {
  color: inherit;
  font-size: inherit;
}

.gallery-modal > .slides {
  position: absolute;
}

.gallery-modal.blueimp-gallery-controls .prev,
.gallery-modal.blueimp-gallery-controls .next {
  width: 40px;
  height: 40px;
  margin-top: calc(-20px - 13px);
  transform: rotate(45deg);
  transition: all .4s;
  border-width: 1px;
  background-color: transparent;
  border-radius: 0;
}

.gallery-modal.blueimp-gallery-controls .prev {
  left: 1.5rem;
  border-color: transparent transparent #fff #fff;
}

.gallery-modal.blueimp-gallery-controls .next {
  right: 1.5rem;
  border-color: #fff #fff transparent transparent;
}

html.rtl .gallery-modal.blueimp-gallery-controls .prev {
  left: auto;
  right: 40px;
}

html.rtl .gallery-modal.blueimp-gallery-controls .next {
  right: auto;
  left: 40px;
}

html.rtl .gallery-modal.blueimp-gallery-controls .prev,
html.rtl .gallery-modal.blueimp-gallery-controls .next {
  transform: scaleX(-1) rotate(45deg);
}

.gallery-modal__bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}

.gallery-modal__bottom .container {
  padding-top: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
}

html.rtl .gallery-modal__bottom {
  direction: rtl;
}

.gallery-modal__info {
  margin: 0 -1rem;
}

.gallery-modal__info .description {
  padding: 0 1rem 0.5rem;
}

.gallery-modal__info .toggle-thumbs {
  padding: 0 1rem 0.5rem;
  text-transform: inherit;
}

.gallery-modal__info .toggle-thumbs:before {
  display: inline-block;
  content: 'Show list';
}

.gallery-modal__info .toggle-thumbs[aria-expanded=true]:before {
  content: 'Hide list';
}

.gallery-modal__info .toggle-thumbs[aria-expanded=true] i:before {
  display: block;
  transform: rotate(180deg);
}

.gallery-modal__thumbs {
  padding-bottom: 0.5rem;
}

.gallery-modal__thumbs .swiper-slide {
  width: 100px;
  height: 70px;
  opacity: 1;
  border: none;
  border-radius: 0;
  box-shadow: none;
  background-size: cover;
  background-position: center;
}

.gallery-modal__thumbs .swiper-slide:hover, .gallery-modal__thumbs .swiper-slide.active {
  opacity: 1;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

@media (max-width: 991px) {
  .gallery-modal > .slides > .slide > .slide-content {
    position: absolute;
    object-fit: cover;
    /* support for plugin https://github.com/bfred-it/object-fit-images */
    font-family: 'object-fit: cover;';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .gallery-modal__bottom .container {
    max-width: none;
    width: auto;
    margin: 1rem;
  }
}

@media (max-width: 991px) and (orientation: landscape) {
  .gallery-modal__bottom .container {
    height: 0;
    overflow: hidden;
  }
}

/**
 * Flights
 */
.list-flight-item {
  margin-bottom: 1.875rem;
}

.flight-card__row .list-group-item:last-child {
  border-bottom: none;
}

.flight-card__row .title {
  margin-left: auto;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  white-space: nowrap;
}

.flight-card__total .total {
  font-weight: 700;
  font-size: 1.875rem;
}

.flight-card__total .btn {
  padding: 0.75rem 1.85rem;
}

@media (min-width: 768px) {
  .flight-card {
    padding: 0.625rem 1.25rem;
  }
  .flight-card .list-group-item {
    padding: 10px;
    margin: 0;
    border: none;
  }
  .flight-card .list-group-item > .row {
    margin: 0;
  }
  .flight-card .list-group-item > .row > * {
    padding: 0;
  }
  .ie11 .flight-card .list-group-item > .row > * {
    flex-basis: auto;
  }
  .flight-card__row {
    border-right: 1px solid #dcdcdc;
  }
  html.rtl .flight-card__row {
    border-right: none;
    border-left: 1px solid #dcdcdc;
  }
  .flight-card__row .list-group-item {
    width: 20%;
  }
}

/**
 * Blog list, blog posts
 */
.blog-list-item {
  margin-bottom: 1.875rem;
}

.blog-list-item .blog-post {
  margin-bottom: 0;
}

.blog-post-wrap {
  color: inherit;
  display: block;
  transition: box-shadow 300ms;
}

.blog-post-wrap:hover {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.blog-post-wrap:hover {
  text-decoration: none;
}

.blog-post {
  display: block;
  padding: 1.5rem;
  margin-bottom: 1.875rem;
}

.blog-post__title {
  font-size: 1.5rem;
}

.blog-post__meta {
  color: #666666;
  font-size: 12px;
}

.blog-post__meta > * {
  display: inline-flex;
  align-items: center;
  margin-bottom: 8px;
  margin-right: 1.25rem;
}

html.rtl .blog-post__meta > * {
  margin-right: 0;
  margin-left: 1.25rem;
}

.blog-post__meta .icon {
  font-size: 115%;
  margin-right: 6px;
}

html.rtl .blog-post__meta .icon {
  margin-right: 0;
  margin-left: 6px;
}

.blog-post__cover {
  position: relative;
  z-index: 1;
}

.blog-post__cover .img-link,
.blog-post__cover .img-wrap {
  overflow: hidden;
}

.blog-post__cover img {
  transition: transform 200ms;
  z-index: -1;
}

.blog-post-wrap:hover .blog-post__cover .img-link {
  filter: grayscale(100%);
}

.blog-post-wrap:hover .blog-post__cover img {
  transform: scale(1.15);
}

.blog-post__cover .img-link, .blog-post__thumb .img-link {
  display: block;
  transition: 200ms ease;
}

.blog-post__cover .img-link:hover, .blog-post__thumb .img-link:hover {
  filter: grayscale(75%);
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.blog-post__cover .img-link,
.blog-post__cover .img-wrap, .blog-post__thumb .img-link,
.blog-post__thumb .img-wrap {
  position: relative;
}

.blog-post__cover img, .blog-post__thumb img {
  z-index: 1;
}

.blog-post__cover .img-link,
.blog-post__cover .img-wrap {
  padding-top: 90%;
}

.blog-post__thumb .img-link {
  min-height: 260px;
  padding-top: 56.25%;
}

.blog-post__meta-tags > * {
  display: inline-block;
}

.blog-post__more-link {
  font-weight: 700;
  white-space: nowrap;
}

.blog-post__more-link > * {
  vertical-align: middle;
}

.blog-post__more-link .icon {
  transition: 200ms;
}

.blog-post-wrap:hover .blog-post__more-link .icon {
  transform: translateX(10px);
}

@media (min-width: 576px) {
  .blog-post__cover .img-link,
  .blog-post__cover .img-wrap {
    padding-top: 57.75%;
  }
  .blog-post__thumb .img-link,
  .blog-post__thumb .img-wrap {
    height: 100%;
    padding: 0;
  }
}

.blog-post-comments h3 {
  margin-bottom: 1.875rem;
}

.comment-post {
  margin-bottom: 1.875rem;
}

.comment-post .comment-post {
  padding-left: 13%;
}

html.rtl .comment-post .comment-post {
  padding-left: 0;
  padding-right: 13%;
}

.comment-post .card {
  padding: 0.875rem 1.5rem;
  margin-bottom: 1.875rem;
}

.comment-post__left {
  min-width: 180px;
  max-width: 28.3871%;
  width: 100%;
  padding-right: 1.25rem;
}

html.rtl .comment-post__left {
  padding-right: 0;
  padding-left: 1.25rem;
}

.comment-post__divider {
  flex-grow: 1;
  height: auto;
  width: 100%;
  transition: width 200ms;
}

.comment-post__avatar {
  border-radius: 50%;
  overflow: hidden;
}

.comment-post__meta .name {
  font-weight: 600;
  color: #111111;
}

.comment-post__meta .date {
  color: #adb5bd;
}

.comment-post__desc {
  flex-grow: 1;
}

.comment-post__desc p:last-of-type {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .comment-post__left {
    min-width: 180px;
    max-width: 28.3871%;
    width: 100%;
  }
  .comment-post__divider.hr {
    width: 1px;
    border-right: 1px solid #dcdcdc;
    border-width: 0 1px 0 0;
    margin: 0 1.25rem 0 0;
  }
  html.rtl .comment-post__divider.hr {
    margin-right: 0;
    margin-left: 1.25rem;
  }
}

.form-reaply {
  padding: 2.1875rem;
  margin-bottom: 1.875rem;
}

.form-reaply .form-group {
  margin-bottom: 1.75rem;
}

/**
 * Page contacts
 */
.contacts-layer {
  position: relative;
  width: 100%;
  height: 100vw;
  padding: 1rem;
  margin-bottom: 1.875rem;
}

.contacts-layer__box {
  position: relative;
  height: 100%;
}

.contacts-layer__map {
  height: 100%;
}

@media (min-width: 576px) {
  .contacts-layer {
    height: 50vh;
  }
}

@media (orientation: landscape) {
  .contacts-layer {
    height: 85vh;
  }
}

@media (min-width: 992px) {
  .contacts-layer {
    height: auto;
    padding: 1.75rem;
  }
}

/**
 * Sidebar
 */
.sidebar__card,
.sidebar .card:not(.modal) {
  display: block;
  padding: 1rem;
  margin-bottom: 1.875rem;
}

.sidebar__card h4,
.sidebar .card:not(.modal) h4 {
  margin-top: 1rem;
}

.sidebar__card .card-title,
.sidebar .card:not(.modal) .card-title {
  margin-top: 0;
  font-size: 1.125rem;
}

.sidebar__card-meta .likes {
  color: #666666;
}

.sidebar__card-meta .likes i {
  color: #999999;
}

.sidebar__list a {
  display: block;
  font-weight: 600;
  line-height: 2.15385;
}

.sidebar__list .title {
  font-size: 1rem;
  line-height: 1.5;
}

.sidebar__list .title a {
  line-height: inherit;
}

.sidebar__contacts {
  font-weight: 700;
}

.sidebar__contacts > li {
  margin-bottom: 1.25rem;
}

.sidebar__contacts p:last-of-type {
  margin-bottom: 0;
}

.sidebar__finder label {
  display: block;
  margin-bottom: 0.50rem;
  line-height: 1;
}

.sidebar__finder .form-control.date {
  padding-left: 2.375rem;
}

.sidebar__finder .form-group {
  margin-bottom: 1rem;
}

.sidebar__finder .form-group i.icon {
  color: #666666;
}

.sidebar__finder .row {
  margin: 0 -5px;
}

.sidebar__finder .row > * {
  padding: 0 5px;
}

@media (max-width: 991px) {
  .sidebar__finder {
    margin-bottom: 0 !important;
  }
}

/**
 * Sitebar guests
 */
.guests-room__item {
  margin-bottom: 1.875rem;
}

.guests-room__item:last-child {
  margin-bottom: 0;
}

.guests-room__desc {
  position: relative;
  padding: 1.25rem 0.75rem;
  margin-bottom: 1.75rem;
}

.guests-room__desc:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 24px;
  border-style: solid;
  border-width: 1.0625rem 0.5rem 0px;
}

html.rtl .guests-room__desc:after {
  left: auto;
  right: 24px;
}

.guests-room__desc p:last-of-type {
  margin-bottom: 0;
}

.guests-room__bottom {
  margin-left: 0.75rem;
}

html.rtl .guests-room__bottom {
  margin-left: 0;
  margin-right: 0.75rem;
}

.guests-room__avatar {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 40px;
  height: 40px;
  background-color: rgba(29, 198, 200, 0.2);
  border-radius: 50%;
  overflow: hidden;
  margin-right: 1rem;
  border: 1px solid #dcdcdc;
  background-color: #cccccc;
  overflow: hidden;
}

.guests-room__avatar img {
  width: 54px;
}

html.rtl .guests-room__avatar {
  margin-right: 0;
  margin-left: 1rem;
}

.guests-room .country {
  color: #666666;
}

/**
* Sidebar Filters
*/
.sidebar-filter .btn-toggle {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: auto;
  width: 20px;
}

.sidebar-filter .modal-header {
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 1rem;
  border: none;
  border-radius: 0;
  z-index: 10;
}

.sidebar-filter .modal-title {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.75rem 1rem;
  margin: 0 -1rem;
  color: #999999;
  white-space: nowrap;
  border-bottom: 1px solid #dcdcdc;
  color: inherit;
}

.sidebar-filter .modal-title .search-result {
  white-space: nowrap;
}

.sidebar-filter .modal-title .clear {
  flex-shrink: 0;
  font-size: 1.4rem;
  opacity: 1;
  color: inherit;
}

.sidebar-filter .modal-title .clear:hover {
  opacity: 0.60;
}

.sidebar-filter .modal-dialog {
  max-width: 720px;
  padding-left: 24px;
  margin: 5rem auto 0;
}

html.rtl .sidebar-filter .modal-dialog {
  padding-left: 0;
  padding-right: 24px;
}

.sidebar-filter .modal-content {
  border: none;
}

.sidebar-filter .modal-body {
  padding-top: 0;
}

@media (min-width: 992px) {
  .sidebar-filter.modal {
    display: block !important;
    position: static;
    opacity: 1;
    left: auto;
    right: auto;
  }
  .sidebar-filter .btn-toggle {
    display: none;
  }
  .sidebar-filter .modal-header {
    position: static;
  }
  .sidebar-filter .modal-title {
    padding: 0.65625rem 0;
    margin: 0;
  }
  .sidebar-filter .search-result {
    flex-grow: 1;
  }
  .sidebar-filter .modal-dialog {
    padding: 0;
    margin: 0;
    transform: none !important;
  }
}

.sidebar-filter__item {
  border-bottom: 1px solid #dcdcdc;
}

.sidebar-filter__item:last-child {
  border-bottom: none;
}

.sidebar-filter__item .form-group:last-of-type {
  margin-bottom: 0;
}

.sidebar-filter__title {
  margin: 0;
  z-index: 3;
  font-weight: 600;
  font-family: inherit;
}

.sidebar-filter__toggle {
  margin: 0.75rem 0;
  font-size: 14px;
  color: inherit;
  background-color: #fff;
}

.sidebar-filter__toggle i {
  font-size: 1.28571em;
  line-height: 1.33333;
  transform: rotate(0);
  transition: transform 300ms;
  color: #999999;
}

.sidebar-filter__toggle[aria-expanded=false] i {
  transform: rotate(-90deg);
}

.sidebar-filter__content {
  padding-bottom: 14px;
}

.sidebar-filter__content > *:last-child {
  margin-bottom: 0;
}

.sidebar-filter__stars {
  font-size: 1.25rem;
}

.sidebar-filter__stars .amount {
  color: #666666;
  font-size: 12px;
}

.sidebar-filter__rating {
  margin-bottom: 12px;
}

.sidebar-filter__rating .amount {
  font-size: 14px;
  width: 1.25rem;
  line-height: 1;
  text-align: left;
  border: none;
}

.sidebar-filter__rating .amount:last-child {
  text-align: right;
}

/**
 * Site footer
 */
.page-footer .h3 {
  margin-bottom: 2.25rem;
}

.page-footer .h4 {
  margin-bottom: 12px;
}

.page-footer__cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.page-footer__cover:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.page-footer__address {
  margin-bottom: 1.25rem;
}

.page-footer__copyright {
  padding-bottom: 14px;
}

.page-footer__copyright .hr {
  margin-bottom: 14px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

@media (max-width: 767px) {
  .page-footer .h3 {
    margin-bottom: 1.5rem;
  }
}

.footer-social > * {
  margin-bottom: 0.75rem;
  font-size: 24px;
}

.footer-social .social-link {
  transition: 120ms ease;
}

.subscribe-form .form-control {
  font-weight: normal;
}

.subscribe-form .btn {
  padding: 0 1.875rem;
}

/**
* Backgrounds and colors theme
*/
a {
  color: #1dc6c8;
}

a:hover {
  color: #138485;
}

ul.list-bullets li:before {
  color: #1dc6c8;
}

.rating {
  color: #fd962c;
}

.bg-primary {
  background-color: #1dc6c8 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #179a9b !important;
}

.bg-primary-light {
  background-color: rgba(29, 198, 200, 0.2) !important;
}

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: rgba(23, 154, 155, 0.2) !important;
}

.bg-secondary {
  background-color: #fd962c !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #f47d02 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #1dc6c8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #179a9b !important;
}

.bg-warning {
  background-color: #fd962c !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f47d02 !important;
}

.bg-danger {
  background-color: #df3603 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ad2a02 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #333333 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1a1a1a !important;
}

.bg-purple {
  background-color: #523da2 !important;
}

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #3f2f7d !important;
}

.text-primary {
  color: #1dc6c8 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #179a9b !important;
}

.text-primary-light {
  color: rgba(29, 198, 200, 0.2) !important;
}

a.text-primary-light:hover, a.text-primary-light:focus {
  color: rgba(23, 154, 155, 0.2) !important;
}

.text-secondary {
  color: #fd962c !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #f47d02 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #1dc6c8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #179a9b !important;
}

.text-warning {
  color: #fd962c !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #f47d02 !important;
}

.text-danger {
  color: #df3603 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #ad2a02 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #333333 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1a1a1a !important;
}

.text-purple {
  color: #523da2 !important;
}

a.text-purple:hover, a.text-purple:focus {
  color: #3f2f7d !important;
}

.alert-primary {
  color: #176f70;
  background-color: #d2f4f4;
  border-color: #1dc6c8;
}

.alert-primary hr {
  border-top-color: #1ab0b2;
}

.alert-primary .alert-link {
  color: #0e4546;
}

.alert-primary-light {
  color: rgba(18, 36, 37, 0.584);
  background-color: rgba(249, 253, 254, 0.84);
  border-color: rgba(29, 198, 200, 0.2);
}

.alert-primary-light hr {
  border-top-color: rgba(26, 176, 178, 0.2);
}

.alert-primary-light .alert-link {
  color: rgba(1, 3, 3, 0.584);
}

.alert-secondary {
  color: #8c561f;
  background-color: #ffead5;
  border-color: #fd962c;
}

.alert-secondary hr {
  border-top-color: #fd8913;
}

.alert-secondary .alert-link {
  color: #623c16;
}

.alert-success {
  color: #1d5f2c;
  background-color: #d4edda;
  border-color: #28a745;
}

.alert-success hr {
  border-top-color: #23923d;
}

.alert-success .alert-link {
  color: #11381a;
}

.alert-info {
  color: #176f70;
  background-color: #d2f4f4;
  border-color: #1dc6c8;
}

.alert-info hr {
  border-top-color: #1ab0b2;
}

.alert-info .alert-link {
  color: #0e4546;
}

.alert-warning {
  color: #8c561f;
  background-color: #ffead5;
  border-color: #fd962c;
}

.alert-warning hr {
  border-top-color: #fd8913;
}

.alert-warning .alert-link {
  color: #623c16;
}

.alert-danger {
  color: #7c240a;
  background-color: #f9d7cd;
  border-color: #df3603;
}

.alert-danger hr {
  border-top-color: #c63003;
}

.alert-danger .alert-link {
  color: #4d1606;
}

.alert-light {
  color: #898a8a;
  background-color: #fefefe;
  border-color: #f8f9fa;
}

.alert-light hr {
  border-top-color: #e9ecef;
}

.alert-light .alert-link {
  color: #707070;
}

.alert-dark {
  color: #232323;
  background-color: #d6d6d6;
  border-color: #333333;
}

.alert-dark hr {
  border-top-color: #262626;
}

.alert-dark .alert-link {
  color: #0a0a0a;
}

.alert-purple {
  color: #33285c;
  background-color: #dcd8ec;
  border-color: #523da2;
}

.alert-purple hr {
  border-top-color: #49368f;
}

.alert-purple .alert-link {
  color: #1f1938;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #1dc6c8;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #1dc6c8;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #1dc6c8;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #1dc6c8;
}

.tooltip-inner {
  background-color: #1dc6c8;
}

.btn-primary {
  color: #fff;
  background-color: #1dc6c8;
  border-color: #1dc6c8;
}

.btn-primary:hover {
  color: #fff;
  background-color: #18a5a7;
  border-color: #179a9b;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 198, 200, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #1dc6c8;
  border-color: #1dc6c8;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #179a9b;
  border-color: #158f90;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 198, 200, 0.5);
}

.btn-primary-light {
  color: #fff;
  background-color: rgba(29, 198, 200, 0.2);
  border-color: rgba(29, 198, 200, 0.2);
}

.btn-primary-light:hover {
  color: #fff;
  background-color: rgba(24, 165, 167, 0.2);
  border-color: rgba(23, 154, 155, 0.2);
}

.btn-primary-light:focus, .btn-primary-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 198, 200, 0.5);
}

.btn-primary-light.disabled, .btn-primary-light:disabled {
  color: #fff;
  background-color: rgba(29, 198, 200, 0.2);
  border-color: rgba(29, 198, 200, 0.2);
}

.btn-primary-light:not(:disabled):not(.disabled):active, .btn-primary-light:not(:disabled):not(.disabled).active,
.show > .btn-primary-light.dropdown-toggle {
  color: #fff;
  background-color: rgba(23, 154, 155, 0.2);
  border-color: rgba(21, 143, 144, 0.2);
}

.btn-primary-light:not(:disabled):not(.disabled):active:focus, .btn-primary-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 198, 200, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #fd962c;
  border-color: #fd962c;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #fd8306;
  border-color: #f47d02;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 150, 44, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #fd962c;
  border-color: #fd962c;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #f47d02;
  border-color: #e77602;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 150, 44, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #1dc6c8;
  border-color: #1dc6c8;
}

.btn-info:hover {
  color: #fff;
  background-color: #18a5a7;
  border-color: #179a9b;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 198, 200, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #1dc6c8;
  border-color: #1dc6c8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #179a9b;
  border-color: #158f90;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 198, 200, 0.5);
}

.btn-warning {
  color: #fff;
  background-color: #fd962c;
  border-color: #fd962c;
}

.btn-warning:hover {
  color: #fff;
  background-color: #fd8306;
  border-color: #f47d02;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 150, 44, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #fff;
  background-color: #fd962c;
  border-color: #fd962c;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #f47d02;
  border-color: #e77602;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 150, 44, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #df3603;
  border-color: #df3603;
}

.btn-danger:hover {
  color: #fff;
  background-color: #b92d02;
  border-color: #ad2a02;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 54, 3, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #df3603;
  border-color: #df3603;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ad2a02;
  border-color: #a02702;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 54, 3, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
}

.btn-dark:hover {
  color: #fff;
  background-color: #202020;
  border-color: #1a1a1a;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1a1a;
  border-color: #131313;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
}

.btn-purple {
  color: #fff;
  background-color: #523da2;
  border-color: #523da2;
}

.btn-purple:hover {
  color: #fff;
  background-color: #443386;
  border-color: #3f2f7d;
}

.btn-purple:focus, .btn-purple.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 61, 162, 0.5);
}

.btn-purple.disabled, .btn-purple:disabled {
  color: #fff;
  background-color: #523da2;
  border-color: #523da2;
}

.btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
.show > .btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #3f2f7d;
  border-color: #3b2c74;
}

.btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 61, 162, 0.5);
}

.btn-outline-primary {
  color: #1dc6c8;
  background-color: transparent;
  background-image: none;
  border-color: #1dc6c8;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #1dc6c8;
  border-color: #1dc6c8;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 198, 200, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #1dc6c8;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #1dc6c8;
  border-color: #1dc6c8;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 198, 200, 0.5);
}

.btn-outline-primary-light {
  color: rgba(29, 198, 200, 0.2);
  background-color: transparent;
  background-image: none;
  border-color: rgba(29, 198, 200, 0.2);
}

.btn-outline-primary-light:hover {
  color: #fff;
  background-color: rgba(29, 198, 200, 0.2);
  border-color: rgba(29, 198, 200, 0.2);
}

.btn-outline-primary-light:focus, .btn-outline-primary-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 198, 200, 0.5);
}

.btn-outline-primary-light.disabled, .btn-outline-primary-light:disabled {
  color: rgba(29, 198, 200, 0.2);
  background-color: transparent;
}

.btn-outline-primary-light:not(:disabled):not(.disabled):active, .btn-outline-primary-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary-light.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 198, 200, 0.2);
  border-color: rgba(29, 198, 200, 0.2);
}

.btn-outline-primary-light:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 198, 200, 0.5);
}

.btn-outline-secondary {
  color: #fd962c;
  background-color: transparent;
  background-image: none;
  border-color: #fd962c;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #fd962c;
  border-color: #fd962c;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 150, 44, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #fd962c;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #fd962c;
  border-color: #fd962c;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 150, 44, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #1dc6c8;
  background-color: transparent;
  background-image: none;
  border-color: #1dc6c8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #1dc6c8;
  border-color: #1dc6c8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 198, 200, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #1dc6c8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #1dc6c8;
  border-color: #1dc6c8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 198, 200, 0.5);
}

.btn-outline-warning {
  color: #fd962c;
  background-color: transparent;
  background-image: none;
  border-color: #fd962c;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #fd962c;
  border-color: #fd962c;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 150, 44, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #fd962c;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #fd962c;
  border-color: #fd962c;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 150, 44, 0.5);
}

.btn-outline-danger {
  color: #df3603;
  background-color: transparent;
  background-image: none;
  border-color: #df3603;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #df3603;
  border-color: #df3603;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 54, 3, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #df3603;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #df3603;
  border-color: #df3603;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 54, 3, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #333333;
  background-color: transparent;
  background-image: none;
  border-color: #333333;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #333333;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
}

.btn-outline-purple {
  color: #523da2;
  background-color: transparent;
  background-image: none;
  border-color: #523da2;
}

.btn-outline-purple:hover {
  color: #fff;
  background-color: #523da2;
  border-color: #523da2;
}

.btn-outline-purple:focus, .btn-outline-purple.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 61, 162, 0.5);
}

.btn-outline-purple.disabled, .btn-outline-purple:disabled {
  color: #523da2;
  background-color: transparent;
}

.btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
.show > .btn-outline-purple.dropdown-toggle {
  color: #fff;
  background-color: #523da2;
  border-color: #523da2;
}

.btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 61, 162, 0.5);
}

.btn-light,
.btn-outline-light {
  color: #fd962c;
}

.btn-nav:hover, .btn-nav:focus {
  background-color: #fff !important;
  color: #fd962c !important;
}

.qty .ui-spinner-input:focus {
  border-color: #fd962c;
}

.qty .ui-spinner-button:hover::after, .qty .ui-spinner-button:focus::after {
  border-color: #fd962c transparent;
}

.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonUp {
  color: #fff;
}

.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonUp:hover {
  background-color: #fd962c;
}

.form-control,
.select2-container .select2-selection--single {
  border-color: #ced4da;
}

.form-control:focus,
.select2-container .select2-selection--single:focus {
  color: #444444;
  background-color: #fff;
  border-color: #1dc6c8;
  outline: 0;
  box-shadow: 0 0 0 0.2rem transparent;
}

.form-control:focus ~ .input-group-append .btn {
  border-color: #1dc6c8;
}

.form-control:focus ~ .form-control.date {
  color: #fd962c;
}

.form-control::placeholder {
  opacity: 1;
  color: #666666;
}

.form-control.flatpickr-input[readonly] {
  background-color: #fff;
}

.custom-checkbox .custom-control-label::after {
  background-image: linear-gradient(to bottom, #fd962c 100%, transparent 100%), linear-gradient(to right, #fd962c 100%, transparent 100%) !important;
}

.custom-radio .custom-control-label:before {
  background-color: #fd962c;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #fd962c;
}

.select2-dropdown .select2-search {
  border-bottom-color: #fd962c;
}

.select2-container .select2-dropdown .select2-results__option--highlighted[aria-selected] {
  background-color: #1dc6c8;
}

.noUi-horizontal.noUi-ltr .noUi-connect {
  background-color: #fd962c;
}

.noUi-horizontal.noUi-ltr .noUi-handle {
  background-color: #fd962c;
}

.progress-bar {
  color: #fff;
  background-color: #1dc6c8;
}

.modal-account__tabs .nav-link {
  background-color: rgba(29, 198, 200, 0.2);
}

.modal-account__tabs .nav-link:hover {
  background-color: rgba(29, 198, 200, 0.3);
}

.modal-account__tabs .nav-link.active {
  background-color: #ffffff;
  border-color: #ffffff;
}

@media (min-width: 768px) {
  .modal-account__list .nav-link:hover {
    background-color: rgba(29, 198, 200, 0.2);
    border-color: rgba(29, 198, 200, 0.2);
  }
}

.breadcrumb-item a {
  color: inherit;
}

.breadcrumb-item a:hover {
  color: #1dc6c8;
}

.page-link {
  color: #666666;
  background-color: #fff;
  border: 1px solid #dcdcdc;
}

.page-link:hover {
  color: #fff;
  background-color: #fd962c;
  border-color: #fd962c;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #1dc6c8;
  border-color: #1dc6c8;
}

.page-item.disabled .page-link {
  color: #666666;
  background-color: #fff;
  border-color: #dcdcdc;
}

.page-content {
  border-bottom-color: #fd962c;
}

.main-nav {
  background-color: #1dc6c8;
}

.main-nav__list .nav-link {
  color: #fff;
  background-color: #1dc6c8;
}

.main-nav__list .nav-link:hover {
  background-color: #29dee0;
}

.main-nav__list .nav-link:hover span {
  border-bottom-color: currentColor;
}

.main-nav__list .nav-link:active:focus {
  background-color: #1cbdbf;
}

.main-nav__list .dropdown-menu .nav-link:focus, .main-nav__list .dropdown-menu .nav-link:hover, .main-nav__list .dropdown-menu .nav-link:active:focus {
  background-color: white;
}

.main-nav__search .form-control {
  box-shadow: inset 0 0 0 1px transparent;
}

.main-nav__search .form-control:focus {
  box-shadow: inset 0 0 0 1px #fd962c;
}

@media (max-width: 991px) {
  .main-nav {
    background-color: #f8f9fa;
  }
  .main-nav__list .nav-item.show > .nav-link {
    color: #1dc6c8;
  }
  .main-nav__list .nav-link {
    background-color: #f8f9fa;
    color: #212529;
  }
  .main-nav__list .nav-link:hover, .main-nav__list .nav-link:active:focus {
    background-color: #e9ecef;
    color: #1dc6c8;
  }
}

.card-intro__footer {
  background-color: rgba(29, 198, 200, 0.9);
}

.card-intro__rating .br-widget a {
  color: inherit;
}

.card-intro__rating .br-widget a::after {
  color: inherit !important;
}

.search-hotels__tabs .nav-link {
  border-color: #d6f9f9;
  background-color: #d6f9f9;
}

.search-hotels__tabs .nav-link:hover {
  background-color: #dffafa;
}

.search-hotels__tabs .nav-link.active {
  background-color: #fff;
  border-color: #fd962c;
  border-bottom-color: #adb5bd;
}

.search-hotels__form .select-local {
  background-color: rgba(29, 198, 200, 0.2);
}

.search-hotels__form .select-local .select2-selection--single {
  background-color: transparent;
}

.search-hotels__form .select-local .select2-selection--single:focus .select2-selection__rendered {
  border-color: #fd962c !important;
}

.search-hotels__form .form-control.date:focus ~ .date {
  border-bottom-color: #fd962c;
}

.card-hover {
  background-color: rgba(29, 198, 200, 0.94);
  box-shadow: 0px 0px 0px 1px rgba(29, 198, 200, 0.94);
}

.card-service .card-header {
  color: #1dc6c8;
}

.sortbar .nav-link.active {
  color: #1dc6c8;
}

.hotel-nav__menu .nav-link {
  color: #666666;
  background-color: rgba(29, 198, 200, 0.2);
}

.hotel-nav__menu .nav-link.active {
  color: #fff;
  background-color: rgba(29, 198, 200, 0.8);
}

.hotel-service__list .available {
  background-color: rgba(29, 198, 200, 0.2);
}

.hotel-status {
  background-color: rgba(29, 198, 200, 0.5);
}

.hotel-package__title:before {
  border-left-color: #fd962c;
}

.hotel-package__props .form-select .select2-container .select2-selection--single {
  background-color: rgba(29, 198, 200, 0.2);
}

.hotel-gallery__thumbs .swiper-slide.swiper-slide-active {
  border-color: #fd962c;
}

.blog-post__meta-tags a {
  color: inherit;
}

.blog-post__meta-tags a:hover {
  color: #fd962c;
}

.sidebar__list a {
  color: inherit;
}

.sidebar__list a:hover {
  color: #1dc6c8;
  text-decoration: none;
}

.sidebar__contacts a {
  color: inherit;
}

.sidebar__contacts a:hover {
  color: #1dc6c8;
}

.guests-room__desc {
  background-color: rgba(29, 198, 200, 0.2);
}

.guests-room__desc:after {
  border-color: rgba(29, 198, 200, 0.2) transparent transparent;
}

.guests-room__avatar:hover {
  border-color: #fd962c;
  box-shadow: 0 0 0 2px #fd962c;
}

.page-footer {
  color: #fff;
  background-color: rgba(29, 198, 200, 0.9);
}

.page-footer a {
  color: inherit;
}

.page-footer a:hover {
  color: #fd962c;
}

.page-footer__cover:before {
  background-color: rgba(29, 198, 200, 0.9);
}

.page-footer__copyright {
  color: rgba(255, 255, 255, 0.7);
}

.page-footer__copyright .hr {
  border-top-color: rgba(255, 255, 255, 0.5);
}

.footer-social {
  color: #fff;
}

.subscribe-form .form-control {
  border-color: #fff !important;
  color: #fff;
  background-color: transparent;
}

.subscribe-form .form-control::placeholder {
  color: inherit;
  opacity: 1;
}

.subscribe-form .btn {
  border-color: #fff !important;
  color: #666666;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #1dc6c8;
  color: #fff;
  border-color: #1dc6c8;
}

.flatpickr-day.selected.startRange + .endRange,
.flatpickr-day.startRange.startRange + .endRange,
.flatpickr-day.endRange.startRange + .endRange {
  box-shadow: -10px 0 0 #1dc6c8;
}

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 0 #1dc6c8, 5px 0 0 #1dc6c8;
}

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #fd962c;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #fd962c;
}

.flatpickr-day.today {
  border-color: #fd962c;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #fd962c;
  background: #fd962c;
  color: #fff;
}

span.flatpickr-day.today:not(.selected),
span.flatpickr-day.prevMonthDay.today:not(.selected),
span.flatpickr-day.nextMonthDay.today:not(.selected) {
  border-color: #e9e9e9;
  border-bottom-color: #fd962c;
}

span.flatpickr-day.today:not(.selected):hover,
span.flatpickr-day.prevMonthDay.today:not(.selected):hover,
span.flatpickr-day.nextMonthDay.today:not(.selected):hover {
  border: 1px solid #fd962c;
}

span.flatpickr-day.startRange,
span.flatpickr-day.prevMonthDay.startRange,
span.flatpickr-day.nextMonthDay.startRange,
span.flatpickr-day.endRange,
span.flatpickr-day.prevMonthDay.endRange,
span.flatpickr-day.nextMonthDay.endRange {
  border-color: #1dc6c8;
}

/**
* Edit RTL
*/
html.rtl .float-left {
  float: right !important;
}

html.rtl .float-right {
  float: left !important;
}

html.rtl .input-group .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
html.rtl .input-group .input-group-append:last-child > .input-group-text:not(:last-child),
html.rtl .input-group .input-group-append:not(:last-child) > .btn,
html.rtl .input-group .input-group-append:not(:last-child) > .input-group-text,
html.rtl .input-group .input-group-prepend > .btn,
html.rtl .input-group .input-group-prepend > .input-group-text {
  border-radius: 0 1.25rem 1.25rem 0;
}

html.rtl .input-group .input-group-append > .btn,
html.rtl .input-group .input-group-append > .input-group-text,
html.rtl .input-group .input-group-prepend:first-child > .btn:not(:first-child),
html.rtl .input-group .input-group-prepend:first-child > .input-group-text:not(:first-child),
html.rtl .input-group .input-group-prepend:not(:first-child) > .btn,
html.rtl .input-group .input-group-prepend:not(:first-child) > .input-group-text {
  border-radius: 1.25rem 0 0 1.25rem;
}

html.rtl .input-group .custom-select:not(:first-child),
html.rtl .input-group .form-control:not(:first-child) {
  border-radius: 1.25rem 0 0 1.25rem;
}

html.rtl .input-group .custom-select:not(:last-child),
html.rtl .input-group .form-control:not(:last-child) {
  border-radius: 0 1.25rem 1.25rem 0;
}

html.rtl .input-group--lg .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
html.rtl .input-group--lg .input-group-append:last-child > .input-group-text:not(:last-child),
html.rtl .input-group--lg .input-group-append:not(:last-child) > .btn,
html.rtl .input-group--lg .input-group-append:not(:last-child) > .input-group-text,
html.rtl .input-group--lg .input-group-prepend > .btn,
html.rtl .input-group--lg .input-group-prepend > .input-group-text {
  border-radius: 0 1.75rem 1.75rem 0;
}

html.rtl .input-group--lg .input-group-append > .btn,
html.rtl .input-group--lg .input-group-append > .input-group-text,
html.rtl .input-group--lg .input-group-prepend:first-child > .btn:not(:first-child),
html.rtl .input-group--lg .input-group-prepend:first-child > .input-group-text:not(:first-child),
html.rtl .input-group--lg .input-group-prepend:not(:first-child) > .btn,
html.rtl .input-group--lg .input-group-prepend:not(:first-child) > .input-group-text {
  border-radius: 1.75rem 0 0 1.75rem;
}

html.rtl .input-group--lg .custom-select:not(:first-child),
html.rtl .input-group--lg .form-control:not(:first-child) {
  border-radius: 1.75rem 0 0 1.75rem;
}

html.rtl .input-group--lg .custom-select:not(:last-child),
html.rtl .input-group--lg .form-control:not(:last-child) {
  border-radius: 0 1.75rem 1.75rem 0;
}

html.rtl .input-group-prepend .form-control {
  padding-left: 1rem;
  padding-right: 2.5rem;
}

html.rtl .input-group-prepend .icon {
  left: auto;
  right: 0;
  margin-left: 0;
  margin-right: 1rem;
}

html.rtl .input-group-append .form-control {
  padding-right: 1rem;
  padding-left: 2.5rem;
}

html.rtl .input-group-append .icon {
  right: auto;
  left: 0;
  margin-right: 0;
  margin-left: 1rem;
}

html.rtl .form-select .select2-selection--single .select2-selection__arrow {
  right: auto;
  left: 0;
  justify-content: flex-end;
}

html.rtl .mCSB_scrollTools {
  left: 0;
  right: auto;
}

html.rtl .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  margin-left: 0;
  margin-right: auto;
}

html.rtl .mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  margin-left: 0;
}

html.rtl .progress {
  margin-right: 0;
  margin-left: 1rem;
}

html.rtl .bootstrap-datetimepicker-widget .fa-chevron-left,
html.rtl .bootstrap-datetimepicker-widget .fa-chevron-right {
  transform: scaleX(-1);
}

@media (min-width: 576px) {
  html.rtl .text-sm-left {
    text-align: right !important;
  }
}

.no-rtl {
  direction: ltr;
  text-align: left;
}

/**
* Demo Switcher
*/
.demo-panel {
  direction: ltr;
  text-align: left;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
  z-index: 2;
  transition: transform 300ms ease 1.5s;
  background-color: #f8f9fa;
}

.demo-panel:hover {
  transform: translateY(0);
  transition: transform 300ms ease 150ms;
}

.demo-panel:hover .demo-panel__toggle i.fa-cog {
  animation: rotateIn 1s backwards;
}

.demo-panel h4 {
  margin: 0;
  margin-right: 0.75rem;
}

.demo-panel__toggle {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 30px;
  font-size: 1.50rem;
  margin-top: -30px;
  border-left: 1px solid #e9ecef;
  border-radius: 6px 6px 0 0;
}

.demo-panel__toggle i.fa-cog {
  animation: rotateOut 1s backwards;
}

@keyframes rotateIn {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotateOut {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}

.demo-panel__content {
  overflow: hidden;
  overflow-x: auto;
}

.demo-panel__content .container {
  min-width: 360px;
}

.demo-panel__colors .nav-link {
  display: inline-block;
  border-radius: 50%;
  padding: 1px;
  margin-bottom: 0.25rem;
  border: 2px solid #fff;
}

.demo-panel__colors .nav-link:after {
  display: block;
  content: '';
  background-color: currentColor;
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 50%;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.demo-panel__colors .nav-link.is-active {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.demo-panel__colors .nav-link[data-path*=default] {
  color: #20c997;
}

.demo-panel__colors .nav-link[data-path*=orange] {
  color: #fd962c;
}

.demo-panel__colors .nav-link[data-path*=green] {
  color: #7dca99;
}

.demo-panel__colors .nav-link[data-path*=red] {
  color: #e2586f;
}

.demo-panel__colors .nav-link[data-path*=purple] {
  color: #523da2;
}

.demo-panel__colors .nav-link[data-path*=pink] {
  color: #e83e8c;
}

.demo-panel__colors .nav-link[data-path*=indigo] {
  color: #6610f2;
}
