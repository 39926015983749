/**
 * Flight searchbar
 */
.flights-search {

  &__content {
    position: relative;
    z-index: 1;
  }

  &__form {
    .row {
      margin: 0 -7px;

      > * {
        padding: 0 7px;
      }
    }
  }

  &__btn {
    width: 35px;
    height: 35px;
    padding: 0.25rem;
    margin: 0 auto;
    border-radius: 1.25rem;
  }

  @include media('>lg') {
    &__btn {
      max-width: 35px;
    }
  }
}
