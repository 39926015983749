/**
 * Site footer
 */
.page-footer {
  .h3 {
    @include rem(margin-bottom, 36px);
  }

  .h4 {
    margin-bottom: 12px;
  }

  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  &__address {
    @include rem(margin-bottom, 20px);
  }

  &__copyright {
    padding-bottom: 14px;

    .hr {
      margin-bottom: 14px;
      border-top: 1px solid rgba($white, 0.50);
    }
  }

  @include media('<md') {
    .h3 {
      @include rem(margin-bottom, 24px);
    }
  }
}

.footer-social {
  > * {
    @include rem(margin-bottom, 12px);
    font-size: 24px
  }

  .social-link {
    transition: 120ms ease;
  }
}

.subscribe-form {
  .form-control {
    font-weight: normal;
  }

  .btn {
    @include rem(padding, 0 30px);
  }
}
