$calendarBackground: #fff;
$calendarBorderColor: #eee;

$monthForeground: #3c3f40;
$monthBackground: transparent;

$weekdaysBackground: transparent;
$weekdays_color: #757575;

$dayForeground: #404848;
$dayHoverBackground: #e9e9e9;
$disabled_border_color: $dayHoverBackground;

$disabled_day_color: rgba(black, 0.15);

$todayColor: $secondary;
$today_fg_color: #fff;

$selectedDayBackground: $primary;
$selectedDayForeground: #fff;

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: $selectedDayBackground;
  color: #fff;
  border-color: $selectedDayBackground;
}
.flatpickr-day.selected.startRange + .endRange,
.flatpickr-day.startRange.startRange + .endRange,
.flatpickr-day.endRange.startRange + .endRange {
          box-shadow: -10px 0 0 $selectedDayBackground;
}
.flatpickr-day.week.selected {
  border-radius: 0;
          box-shadow: -5px 0 0 $selectedDayBackground, 5px 0 0 $selectedDayBackground;
}
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: $todayColor;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: $todayColor;
}
.flatpickr-day.today {
  border-color: $todayColor;
}
.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: $todayColor;
  background: $todayColor;
  color: #fff;
}
span.flatpickr-day.today:not(.selected),
span.flatpickr-day.prevMonthDay.today:not(.selected),
span.flatpickr-day.nextMonthDay.today:not(.selected) {
  border-color: #e9e9e9;
  border-bottom-color: $todayColor;
}
span.flatpickr-day.today:not(.selected):hover,
span.flatpickr-day.prevMonthDay.today:not(.selected):hover,
span.flatpickr-day.nextMonthDay.today:not(.selected):hover {
  border: 1px solid $todayColor;
}
span.flatpickr-day.startRange,
span.flatpickr-day.prevMonthDay.startRange,
span.flatpickr-day.nextMonthDay.startRange,
span.flatpickr-day.endRange,
span.flatpickr-day.prevMonthDay.endRange,
span.flatpickr-day.nextMonthDay.endRange {
  border-color: $selectedDayBackground;
}
