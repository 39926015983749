/**
* Cards
*/
.card {
  border-radius: 0;
  border-color: $hr-border-color;
}

.card-price {
  position: absolute;
  top: 1.25rem;
  right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @include rem(height, 36px);
  min-width: 105px;
  font-size: 13px;
  text-align: center;
  border-radius: $border-radius 0 0 $border-radius;
  color: nth($gray-dark-color, 1);
  background-color: $white;
  z-index: 1;

  .count {
    font-weight: 700;
    font-size: 15px;
  }

  html.rtl & {
    right: auto;
    left: 0;
    @include rem(border-radius, 0 $border-radius $border-radius 0);
  }
}

.card-hover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  color: $white;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s, visibility 0s 0.40s;
  text-align: center;
  z-index: 1;

  h3 {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
  }

  .card-hotel:hover &,
  .card-intro:hover & {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.4s, visibility 0s 0s;
  }

  &__view {
    max-width: 146px;
    width: 100%;
  }
}

.card-hotel {
  position: relative;
  display: block;
  font-size: 14px;
  border-radius: 0;

  &__img {
    position: relative;
    flex-grow: 1;
    padding-top: calc(100% - 25px);
    z-index: 1;
  }

  .card-img-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem 1rem 0.85rem;
    background-color: #fff;
    z-index: 1;

    h4 {
      font-weight: 500;
      font-size: 1.25rem;
    }
  }

  &__local {
    color: nth($gray-dark-color, 5);

    .link {
      color: inherit;
    }
  }
}

.card-service {
  padding: 0 1rem;

  .card-header {
    padding: 1rem 0 1.25rem;
    background-color: transparent;
  }

  h4 {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 1.25rem;
  }

  .card-body {
    padding: 1.25rem 0;
  }
}

.booking-card {
  margin-bottom: 2rem;

  &__form {
    .form-group {
      margin-bottom: 2rem;
    }
  }
}

.bx2dp {
  @include shadow-2dp();
}

.bx4dp {
  @include shadow-4dp();
}

.bx8dp {
  @include shadow-8dp();
}
