.grid-sizer {
  min-height: 0;
  padding: 0;
  margin: 0;
}

.row:not(.no-gutters) {
  @include rem(margin-right, -15px);
  @include rem(margin-left, -15px);

  > * {
    @include rem(padding-right, 15px);
    @include rem(padding-left, 15px);
  }
}

.pad-10:not(.no-gutters) {
  margin-left: 10px;
  margin-right: 10px;

  > * {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@include media('<xl') {
  .pad-lg-20:not(.no-gutters) {
    margin: 0 -10px;

    > * {
      padding: 0 10px;
    }
  }
}

@include media('<md') {
  .container {
    max-width: none;
  }
}

.sticky {
  position: fixed;
}
