.qty {
  .ui-spinner {
    padding-right: 28px;
    border: none;
    border-radius: 0;

    html.rtl & {
      padding-right: 0;
      padding-left: 28px;
    }
  }

  .ui-spinner-input {
    width: 25px;
    height: 25px;
    line-height: 24px;
    padding: 0;
    margin: 0;
    text-align: center;
    border: 1px solid $gray-800;
  }

  .ui-spinner-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    @include rem(padding, 0 10px);
    opacity: 0.60;
    cursor: pointer;

    html.rtl & {
      right: auto;
      left: 0;
    }

    &::after {
      content: '';
      border-style: solid;
      border-width: 8px 4px;
      border-color: $gray-800 transparent;
    }
  }

  .ui-spinner-up {
    &::after {
      border-top-width: 0;
    }
  }

  .ui-spinner-down {
    &::after {
      border-bottom-width: 0;
    }
  }
}
