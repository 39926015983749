.alert {
  border-width: 0;
  border-left-width: 4px;
  border-radius: 0;

  html.rtl & {
    border-left-width: 0;
    border-right-width: 4px;
  }
}
