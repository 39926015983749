.custom-control {
  font-size: 14px;
  line-height: (24/14);
  min-height: 24/14 + em;
  padding-left: 24/14 + em;
  margin-bottom: 0;
  color: $gray-600;

  .custom-control-label {
    &:before {
      width: 16/14 + em;
      height: 16/14 + em;
      box-shadow: none!important;
      cursor: pointer;

      .has-error & {
        border-color: $danger;
      }

      .has-success {
        border-color: $success;
      }
    }
  }
}

.custom-checkbox {
  .custom-control-label {
    &::before {
      border: 2px solid $gray-400;
      border-radius: 0;
      background-color: transparent!important;
    }

    &::after {
      width: 13/14 + em;
      height: 6/14 + em;
      margin: 5/14 + em 0 0 2/14 + em;
      transform: rotate(-45deg);
      background-repeat: no-repeat;
      background-position: 0px -10px, 0px 100%;
      background-size: 2px 100%, 0 2px;
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-position: 0px 0px, 0px 100%;
      background-size: 2px 100%, 100% 2px;
      transition:
        background-position 50ms ease-in,
        background-size 50ms ease-out 50ms;
    }
  }
}

.custom-radio {
  .custom-control-label {
    &:before,
    &:after {
      top: 0;
      bottom: 0;
    }
    &:before {
      margin: auto;
    }
    &::after {
      width: 6px;
      height: 6px;
      margin: auto 5px;
      border-radius: 50%;
      background-color: #fff;
      transform: scale(0);
      transition: transform 120ms;
    }
  }

  .custom-control-input:checked~.custom-control-label {
    &::after {
      transform: scale(1);
    }
  }
}
